import React, { useState, useEffect, useCallback } from "react";
import asavault_logo from "../../assets/Images/asavaultlogo.png";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { SessionData } from "../../types/types";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/css/style.css";
import { SessionState } from "../../redux/sessionSlice";
import { Config } from "../../Utils/AppConstants";
import { makeApiCall } from "../../services/ApiService";
import { API_CALL_TYPE, CREATE_SESSION } from "../../services/Api";

interface SessionResponse {
  status: number;
  message: string;
  reference: Array<{
    requestId: string;
    timeStamp: string;
  }>;
  version: string;
  data: {
    asaSessionCode: string;
    asaConsumerCode: number;
    version: string;
    sessionName: string;
    pageCount: number;
    status: string | null;
    statusMessage: string | null;
    expiryDate: string;
    sessionPages: null | Array<any>;
  };
}
const Authenticate = () => {
  // Throw error if authenticate page directly called without any params
  // validate asaFintechCode
  // validate fintechapplicationcode
  // validate fintechAuthenticationKey
  // validate asaConsumerCode
  // if validation fails, redirect back with a message "Authentication fails"
  // create session as it is created in AsaVault
  // create PWA url as created in AsaVault to direct user to PWA application
  // take user to the fintech PWA ASAPAY Landing Page ie. if paymentMethod

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator

  const passedData = location.state;
  const navigate = useNavigate();
  const sessionData: SessionData | undefined =
    queryClient.getQueryData("sessionData");
  const updatedsessionData: SessionData | undefined =
    queryClient.getQueryData("updatedSessionData");
  const [loading, setLoading] = useState(true);
  const [baseUrl, setBaseurl] = useState("");
  const [statusText, setStatusText] = useState("Authenticating...");

  const [extractedParams, setExtractedParams] = useState<{
    request?: string | null;
    token?: string | null;
    asaFintechCode?: string | null;
    asaFintechApplicationCode?: string | null;
    asaConsumerCode?: string | null;
    SubscriptionPlanCode?: string | null;
    IsNewSubscription?: string | null;
    IsTrialEligible?: string | null;
    Amount?: string | null;
    CouponCode?: string | null;
    PrevSubscriptionPlanCode?: string | null;
    redirecturl?: string | null;
    redirecturlonError?: string | null;
  }>({});

  const handleCreateResponse = async (response: any) => {
    setIsLoading(false);

    // console.log("CREATE SESSION :: session creation:::::" + JSON.stringify(response));

    const authUrl = new URL(Config.AUTHENTICATE_URL);
    if (response?.data?.asaSessionCode) {
      const sessionid = response.data?.asaSessionCode;
      const consumerCOde = response?.data?.asaConsumerCode;

      const constructedUrl = `/portfoliopilot?token=${extractedParams.token}&sessionid=${sessionid}&asaFintechCode=${Config.asaFicode}&asaConsumerCode=${consumerCOde}&version=1.08`;
      // console.log("Constructed URL: ", constructedUrl);
      //   console.log("Base URL: ", baseUrl);
      navigate(constructedUrl);
      //  window.location.href = constructedUrl;
    }
  };

  const handleCreateError = (error: any) => {
    navigate("/error", { state: "PORTFOLIO AUTHENTICATION ERROR" });
  };

  useEffect(() => {
    // Extract the current URL
    const authUrl = new URL(window.location.href);
    // const authUrl = new URL(Config.AUTHENTICATE_URL);

    // Check if the URL only contains "authenticate"
    if (
      authUrl.pathname === "/authenticate" &&
      !authUrl.searchParams.toString()
    ) {
      // Redirect to another screen
      navigate("/error"); // Change this to your desired route
      return;
    }

    // Extract parameters from AUTHENTICATE_URL
    //   const authUrl = new URL(Config.AUTHENTICATE_URL);
    const baseUrl = `${authUrl.origin}/portfoliopilot`; // This will dynamically set the base URL

    const params = new URLSearchParams(authUrl.search);
    setBaseurl(`${authUrl.origin}/portfoliopilot`);
    const extractedParams = {
      request: params.get("request"),
      token: params.get("token"),
      asaFintechCode: params.get("asaFintechCode"),
      asaFintechApplicationCode: params.get("asaFintechApplicationCode"),
      asaConsumerCode: params.get("asaConsumerCode"),
      SubscriptionPlanCode: params.get("SubscriptionPlanCode"),
      IsNewSubscription: params.get("IsNewSubscription"),
      IsTrialEligible: params.get("IsTrialEligible"),
      Amount: params.get("Amount"),
      CouponCode: params.get("CouponCode"),
      PrevSubscriptionPlanCode: params.get("PrevSubscriptionPlanCode"),
      redirecturl: params.get("redirecturl"),
      redirecturlonError: params.get("redirecturlonError"),
    };

    // console.log("dxvkdnkdnvkds" + extractedParams.asaFintechCode);

    setExtractedParams(extractedParams);
    const asaConsumerCodeParam = params.get("asaConsumerCode");

    // Check if the parameter is not null and can be converted to a number
    if (asaConsumerCodeParam !== null) {
      // Parse the string to a number
      Config.asaConsumerCode = parseInt(asaConsumerCodeParam, 10);
    } else {
      // Handle the case where the parameter is null (e.g., set a default value or throw an error)
      console.error("asaConsumerCode parameter is missing or invalid.");
      Config.asaConsumerCode = 0; // Set a default value or handle it according to your needs
    }

    const asaFintechCode = extractedParams.asaFintechCode;

    // Check if the parameter is not null and can be converted to a number
    if (asaFintechCode !== null) {
      // Parse the string to a number
      Config.asaFicode = parseInt(asaFintechCode, 10);
    } else {
      // Handle the case where the parameter is null (e.g., set a default value or throw an error)
      console.error("asaConsumerCode parameter is missing or invalid.");
      Config.asaFicode = 0; // Set a default value or handle it according to your needs
    }
    // console.log("Extracted Parameters:", extractedParams);

    const payload = {
      asaConsumerCode: extractedParams.asaConsumerCode,
      version: "1.08",
      sessionName: "ASAVault",
      pageCount: 0,
      status: null,
      statusMessage: null,
      sessionPages: [
        {
          asaSessionCode: "",
          pageNumber: 0,
          pageName: "ASAVaultDynamicLink",
          pageTitle: null,
          pageDescription: null,
          pageUrl: "/asavault",
          pageStatus: "Completed",
          pageStatusMessage: null,
          pageData: JSON.stringify({
            backActionDynamicLink: extractedParams.redirecturl,
            successDynamicLink: extractedParams.redirecturl,
            linkAccountDynamicLink:
              "https://asavault.page.link/vBy5yb6hahcBNi828",
          }),
        },
        {
          asaSessionCode: "",
          pageNumber: 99,
          pageName: "FintechBillingDetails",
          pageTitle: null,
          pageDescription: null,
          pageUrl: "/fintechBillingDetails",
          pageStatus: "Completed",
          pageStatusMessage: null,
          pageData: JSON.stringify({
            subscriptionPlanCode: extractedParams.SubscriptionPlanCode,
            isNewSubscription: extractedParams.IsNewSubscription,
            isTrialEligible: extractedParams.IsTrialEligible,
            amount: extractedParams.Amount,
            couponCode: extractedParams.CouponCode,
            prevSubscriptionPlanCode: extractedParams.PrevSubscriptionPlanCode,
          }),
        },
      ],
    };
    const getSession = async () => {
      const timer = setTimeout(() => {
        setStatusText("Creating ASAPAY Session...");
      }, 3000); // 3000ms = 3 seconds
      //   setStatusText("Creating Session..."); // Update status text
      try {
        const response = await makeApiCall(
          API_CALL_TYPE.POST_CALL, // Specify the API call type (POST in this case)
          CREATE_SESSION(), // API endpoint to create a session
          handleCreateResponse, // Callback function to handle successful response
          handleCreateError, // Callback function to handle error response
          "", // Placeholder for formData argument
          "", // Placeholder for token argument
          payload // The payload for the POST request
        );

        // Handle success - maybe show a success message to the user
      } catch (error) {
        // Handle error - maybe show an error message to the user
      }
    };

    // Call the API
    getSession();

    // setLoading(false); // Update loading state
  }, [dispatch, navigate, sessionData]);

  // Function to validate the session data
  const validateSession = (sessionData: SessionData): boolean => {
    // Perform validation based on specific keys or properties
    // For example:
    // return sessionData.key === "someValue";
    return true;
  };

  return (
    <div className="flex bg-theme asavault_logo  flex-col items-center justify-center h-screen">
      <img src={asavault_logo} alt="ASA Vault Logo" className="h-16 mb-4" />
      <p className="text-base text-white poppins-medium mb-2">{statusText}</p>
      {loading && (
        <div className="animate-spin h-8 w-8 border-t-2 border-b-2 border-white-900 rounded-full"></div>
      )}
    </div>
  );
};

export default Authenticate;
