export const Config = {
   API_URL: "https://api.asavault.com/",
  X_ASA_version: 1.08,
  sessionID: "",
  subscriptionKey: "",
  token: "",
  asaFicode: 12345679, // Declare type as string or null
  asaConsumerCodee: "",
  asaConsumerCode: 2037376870,
  dynamicLink: "",
  asaFintechCode: 12345679,



  AUTHENTICATE_URL: `https://appdev.asavault.com/authenticate?
request=FintechBillingPage
&token=njhutsb
&asaFintechCode=618038590
&asaFintechApplicationCode=124
&asaConsumerCode=1996291339
&SubscriptionPlanCode=18
&IsNewSubscription=YES
&IsTrialEligible=NO
&Amount=125
&CouponCode=BUY2024
&PrevSubscriptionPlanCode=16
&redirecturl=https://portfoliopilot.com/asa/asa_landing?confirmation=&message=
&redirecturlonError=https://portfoliopilot.com/asa/asa_landing/error?message=Authentication%20Failed
`,
// &asaConsumerCode=1685949699
//    AUTHENTICATE_URL: `https://appdev.asavault.com/authenticate?
// request=FintechBillingPage
// &asaFintechCode=618038590
// &asaConsumerCode=939168496
// &redirecturl=https://sandbox.portfoliopilot.com/track?onboarding=complete&view=Preferences?sync=true
// &SubscriptionPlanCode=16
// &IsNewSubscription=YES&IsTrialEligible=YES&Amount=99.0&token=undefined&redirecturlonError=https://sandbox.portfoliopilot.com/track?onboarding=complete&view=Preferences
// `,




  
  // AUTHENTICATE_URL: `https://appdev.asavault.com/authenticate
// `,

  BROWSER_URL: 
  
  // "https://appdev.asavault.com/guac?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=1784549021&asaFintechCode=1282849331&asaConsumerCode=518218267&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",
  "https://appdev.asavault.com/onegoal?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=813782067&asaFintechCode=1054208782&asaConsumerCode=89730242&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",
  // "https://appdev.asavault.com/crb?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJNMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6IjliNzc1MDAzLTUyZTctNGViNi1iODJmLTJlMTBmMGQ4YWM3ZSIsIkRldmljZUlkIjoiQkI3M0EzQUQtRUVDRS00RTc0LUE3QTMtQkVFNEZGOEM1M0JGIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAzLTEwVDA5OjI5OjUxLjQ1Mjg0NzVaIiwianRpIjoiNWQ1YmJhNTUtZGJiMC00YzZmLTg3YzQtNzFjZmY1YmU3YjMxIiwibmJmIjoxNzI2MDQ2OTkxLCJleHAiOjE3NDE1OTg5OTEsImlhdCI6MTcyNjA0Njk5MSwiaXNzIjoiaHR0cHM6Ly9saW5rZmlkZXYuYXNhY29yZS5jb20iLCJhdWQiOiJodHRwczovL2xpbmtmaWRldi5hc2Fjb3JlLmNvbSJ9.j5hf7IR2gZs2SyXpi-it00syD72vEzKxWyirJrsRQJ4&sessionid=1952286788&asaFiCode=2017905674&asaConsumerCode=113713333&dynamicLink=https%3A%2F%2Fasavault.page.link%2FLtrHp9prBnen66xT7&version=1.08",

  // "https://appdev.asavault.com/crb?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMjkyIiwiVXNlck5hbWUiOiJtMTFAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiIxODE4MjE2OTciLCJUb2tlblRhZyI6IjkwNmFmMWU0LTZkNWQtNGUyMi1hMzE3LTFlNjYzNzM0YWJjMyIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNC0xMS0yN1QwOTo0Nzo0MC40Njc3Njc5WiIsImp0aSI6IjFjMDJlN2RhLTQwODItNGYyMi04Mzk0LTdjYjBhN2E0MzZhZCIsIm5iZiI6MTcxNzE0ODg2MCwiZXhwIjoxNzMyNzAwODYwLCJpYXQiOjE3MTcxNDg4NjAsImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.-zjI8xQTJRGTUC82X9f2Di4tEV4OhoQ1Bx4H-BvjvOM&sessionid=620636830&asaFiCode=2017905674&asaConsumerCode=63572153&dynamicLink=https%3A%2F%2Fasavault.page.link%2FnDQMwJdmg64kfQAv6&version=1.08",
  // "https://appdev.asavault.com/hireandfirekids?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJNMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6ImVjODY3YmY1LTgxYzItNDMxOS1hYjBlLThjZGU3MmIxNDUwZSIsIkRldmljZUlkIjoiQkI3M0EzQUQtRUVDRS00RTc0LUE3QTMtQkVFNEZGOEM1M0JGIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAzLTA4VDE1OjE1OjQ4LjQ5MjI1NTRaIiwianRpIjoiNzdiZmVmZmQtNDgxYS00ZTAyLWE4M2YtZTM0MzE4YmUxMDgyIiwibmJmIjoxNzI1ODk0OTQ4LCJleHAiOjE3NDE0NDY5NDgsImlhdCI6MTcyNTg5NDk0OCwiaXNzIjoiaHR0cHM6Ly9saW5rZmlkZXYuYXNhY29yZS5jb20iLCJhdWQiOiJodHRwczovL2xpbmtmaWRldi5hc2Fjb3JlLmNvbSJ9.lTuPIh3ARu8QyFZXKPN5cPbkAx3lTCV92uSoM4RosQQ&sessionid=2491068&asaFintechCode=1479515214&asaConsumerCode=250152904&dynamicLink=https%3A%2F%2Fasavault.page.link%2F7Wd9TVfegsJbJ8Ng8&version=1.08",
    //  "https://appdev.asavault.com/guac?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=2491068&asaFintechCode=1282849331&asaConsumerCode=250152904&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",
  // "https://appdev.asavault.com/onegoal?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=598191755&asaFintechCode=1054208782&asaConsumerCode=727974936&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",
  rupeeSymbol: "\u20B9",
};

