import React, { useEffect, useState, useCallback } from "react";
import arrow_back_ios_new from "../../../assets/Images/arrow_back_ios_new.png";
import pwa_image from "../../../assets/Images/pwa_image.png";
import visa from "../../../assets/Images/visa.png";
import city_double_cash from "../../../assets/Images/city_double_cash.png";
import arrow_up_black from "../../../assets/Images/arrow_up_black.png";
import check_everyday from "../../../assets/Images/check_everyday.png";
import dropdown_arrow from "../../../assets/Images/arrow_drop_down.png";
import arrow_open from "../../../assets/Images/arrow_drop_down_portfolio.svg"; // Assuming it's the same as dropdown_arrow
import dropdown_arrow_close from "../../../assets/Images/uo_arrow.png";
import more_vert from "../../../assets/Images/more_vert_portfolio.svg";
import edit_icon from "../../../assets/Images/edit.png";
import delete_icon from "../../../assets/Images/delete.png";
import portfolio_logo from "../../../assets/Images/demo/portfolio_main.svg";
import "./PortfolioPayment.css"; // Plan NameImport your CSS file for animations

import add_icon from "../../../assets/Images/add.png";
import { ToastContentProps, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import close_icon from "../../../assets/Images/close_small_portfolio.svg";
import { useNavigate } from "react-router-dom";
import guac from "../../../assets/Images/demo/guac_relative_icon.svg";

import { makeApiCall } from "../../../services/ApiService";
import flash from "../../../assets/Images/flash_white.png";

import {
  API_CALL_TYPE,
  GET_ACTIVE_SUBSCRIPTION,
  GET_BILLING_ADDRESSES,
  GET_CONSUMER_ADDRESS,
  GET_SUBSCRIPTION_PLANS,
  POST_CONSUMER_ADDRESS,
  POST_PAYMENT_API,
  POST_PAYMENT_METHOD,
  SAVE_SESSION,
  UPDATE_SESSION,
} from "../../../services/Api";
import ConfirmDialog from "../../../components/ConfirmDialog ";
import { useDispatch, useSelector } from "react-redux";
import {
  setSessionData,
  setLoading,
  setError,
  SessionState,
  setCOnsumerAdress,
  setSelectedPaymentMethod,
  setSelectedBillingAddress,
  setNewPaymentField,
  setPaymentCVV,
  setDynamicLink,
  setSessionIDStore,
  setAsaConsumerCode,
} from "../../../redux/sessionSlice";
import { data, filter } from "dom7";
import { SessionData, SessionUpdateRequest } from "../../../types/types";
import { Config } from "../../../Utils/AppConstants";
import ConfirmDialogCVV from "../../../components/ConfirmDialogCVV";
import LoaderComponent from "../../../components/LoaderComponent";
import { ToyBrick } from "../../../assets/libs/lucide/cjs/lucide";
import { match } from "assert";
import { Filter } from "@mui/icons-material";
interface PaymentMethod {
  arrow_open: any;
  dropdown_arrow_close: any;
  paymentMethodId: number;
  asaPaymentMethodCode: number;
  isDefault: boolean;
  paymentMethodName: string;
  paymentType: string;
  routingNumber: string | null;
  last4DigitsOfAccountNumber: string | null;
  bankName: string;
  cardNumber: string | null;
  accountNumber: string | null;
  cardType: string | null;
  last4DigitsOfCardNumber: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  cardHolderName: string | null;
  expirationYear: string | null;
  expirationMonth: string | null;
}

interface BillingAddress {
  consumerAddressId: number;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  country: string;
}

// Interface for a single subscription detail
interface SubscriptionDetail {
  billingDetailCode: number;
  asaConsumerCode: number;
  asaFintechCode: number;
  billingDate: string; // ISO 8601 date format as a string
  billingAmount: number;
  status: string;
  comment: string;
  isSubscription: boolean;
  subscriptionPlanCode: number;
  subscriptionTitle: string;
  subscriptionStatus: string;
  subscriptionStartDate: string; // ISO 8601 date format as a string
  subscriptionEndDate: string; // ISO 8601 date format as a string
  isRecurring: boolean;
  recurringAmount: number;
  recurringStartDate: string; // ISO 8601 date format as a string
  nextRecurringScheduleDate: string | null; // ISO 8601 date format as a string or null
  inTrial: boolean;
  billingCycle: string;
}

// Type for the array of subscription details
type FilteredSubscriptions = SubscriptionDetail[];

interface Plan {
  subscriptionPlanCode: number;
  subscriptionTitle: string;
  description: string;
  oneTimeAmount: number;
  recurringAmount: number;
  billingCycle: string;
  asaFintechCode: number;
  isFree: boolean;
  isActive: boolean;
  freeTrialPeriod: number;
  subscriptionIcon: string | null;
  isRecurring: boolean;
  businessId: number;
  createdByName: string | null;
  createdDate: string;
  updatedByName: string | null;
  updatedDate: string | null;
}
interface SubscriptionPlanData {
  subscriptionPlanCode: number;
  subscriptionTitle: string;
  description: string;
  oneTimeAmount: number;
  recurringAmount: number;
  billingCycle: string;
  asaFintechCode: number;
  isFree: boolean;
  isActive: boolean;
  freeTrialPeriod: number;
  subscriptionIcon: string | null;
  isRecurring: boolean;
  businessId: number;
  createdByName: string | null;
  createdDate: Date | string;
  updatedByName: string | null;
  updatedDate: Date | string | null;
}

interface SubscriptionPlan {
  pageName: string;
  pageData: SubscriptionPlanData; // Correct type for pageData
}

const PortfolioPayment: React.FC = () => {
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [cacheCleared, setCacheCleared] = useState(false); // Track cache clearing status
  const [showbillAddress, setShowPBillAddress] = useState(false);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState<
    number | null
  >(null);
  const navigate = useNavigate();
  const [selectedPaymentType, setSelectedPaymentType] = useState<string | null>(
    null
  );
  const [cvv, setCVV] = useState("");
  const [cvvEntered, setCVVEntered] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const [cvvError, setCVVError] = useState("");
  const [subscriptionPlanData, setSubscriptionPlanData] =
    useState<SubscriptionPlan | null>(null);

  const [activePlanData, setActivePlanData] =
    useState<SubscriptionPlanData | null>(null);

  const [previousPlanData, setPreviousPlanData] =
    useState<SubscriptionPlanData | null>(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [filteredPlansData, setFilteredPlansData] =
    useState<FilteredSubscriptions | null>(null);

  const queryClient = useQueryClient();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [moreVertOpen, setMoreVertOpen] = useState(false);
  const [isMoreVertVisible, setIsMoreVertVisible] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [moreVertOpenbill, setMoreVertOpenbill] = useState(false);
  const [isMoreVertBillVisible, setIsMoreVertBillVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<
    number | null
  >(null);
  const [billingAddresses, setBillingAddresses] = useState<BillingAddress[]>(
    []
  ); // State for billing addresses
  const [dynamicLink, setDynamicLink] = useState("");
  const [isBillingDropdownOpen, setIsBillingDropdownOpen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDialogCVV, setShowDialogCVV] = useState(false);
  const [showConfirmDialogPayment, setShowConfirmDialogPayment] =
    useState(false);
  const [deletePaymentIndex, setDeletePaymentIndex] = useState<number | null>(
    null
  );
  const [visibleOptionsIndex, setVisibleOptionsIndex] = useState<number | null>(
    null
  );
  const dispatch = useDispatch();
  const updatedsessionData: SessionUpdateRequest | undefined =
    queryClient.getQueryData("updatedSessionData");
  const [deletePaymentIndexCard, setDeletePaymentIndexCard] = useState<
    number | null
  >(null);
  const storedSessionId = useSelector(
    (state: SessionState) => state?.session.sessionIDStore
  );
  const storedConsumerCode = useSelector(
    (state: SessionState) => state?.session.asaConsumerCode
  );
  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );
  const paymentCVVs = useSelector(
    (state: SessionState) => state.session.paymentCVVs
  );
  const cvvdfromStore = useSelector(
    (state: SessionState) => state?.session.newPaymentField
  );

  // const paymentField = useSelector(
  //   (state: SessionState) => state?.session.newPaymentField
  // );
  const getSelectedBillingfromStore = useSelector(
    (state: SessionState) => state?.session.selectedBillingAddress
  );
  const cvvfromStore = useSelector(
    (state: SessionState) => state?.session.newPaymentField
  );
  const getSelectedPaymentfromStore = useSelector(
    (state: SessionState) => state?.session.selectedPaymentMethod
  );
  //variables
  const [sessionID, setSessionID] = useState<string | null>(null);
  const [sessionFetched, setSessionFetched] = useState(false); // Track if session has been fetched
  const [createdSessionData, setcreatedSessionData] =
    useState<SessionData | null>(null); // Define state variable
  const [getSessionCalled, setGetSessionCalled] = useState(false);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [CurrentSelectedPlan, setcurrentSelectedPlan] = useState<Plan[]>([]);
  const [subscriptionType, setSubscriptionType] = useState("Yearly");
  const [subscriptionAmount, setsubscriptionAmount] = useState(0);
  const [subscriptionPlanCode, setsubscriptionPlanCode] = useState(0);
  const [prevsubscriptionPlanCode, setprevsubscriptionPlanCode] = useState(0);
  const [formattedProratedAmountNew, setFormattedProratedAmountNew] =
    useState<string>("");
  const [formattedProratedDifference, setFormattedProratedDifference] =
    useState<string>("");

  const [scurrentPlan, setscurrentplan] = useState<Plan[]>([]);

  const [prevSelectedPlan, setPreviousSeletedPlan] = useState<Plan[]>([]);

  useEffect(() => {
    // This will run whenever CurrentSelectedPlan changes
    // console.log("Updated CurrentSelectedPlan:", CurrentSelectedPlan);
  }, [CurrentSelectedPlan]);

  // useEffect(() => {
  //   // This will run whenever CurrentSelectedPlan changes
  //   // console.log("Updated CurrentSelectedPlan:", CurrentSelectedPlan);
  // }, [setSelectedPaymentMethod]);
  useEffect(() => {
    // This will run whenever CurrentSelectedPlan changes
    // console.log("Updated CurrentSelectedPlan:", CurrentSelectedPlan);
  }, [setSelectedBillingAddress]);
  useEffect(() => {
    // This will run whenever CurrentSelectedPlan changes
    // console.log("Updated scurrentPlan:", scurrentPlan);
  }, [scurrentPlan]);

  useEffect(() => {
    // This will run whenever CurrentSelectedPlan changes
    // console.log("Previous selected Plan:", prevSelectedPlan);
  }, [prevSelectedPlan]);

  const handleCVVChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    paymentMethodId: number
  ) => {
    const newCVV = e.target.value;
    // Ensure that CVV is not logged or displayed inappropriately
    setCVV(newCVV);
    dispatch(setNewPaymentField(newCVV)); // Only use newCVV here

    // Update CVV in Redux store
    dispatch(setPaymentCVV({ paymentMethodId, cvv: newCVV }));

    if (newCVV.length === 4) {
      setIsDropdownOpen(false);
    }
  };

  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };

  const maskCVV = (cvv: string) => {
    return cvv.replace(/./g, "*"); // Mask all characters
  };

  const handleACtiveSubscriptionError = (error: any) => {
    // navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  const handleSessionError = (error: any) => {
    navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  // useEffect(() => {
  //   const clearCache = async () => {
  //     // Clear local storage, session storage, IndexedDB, and caches
  //     localStorage.clear();
  //     sessionStorage.clear();

  //     const deleteIndexedDB = async () => {
  //       const databases = await indexedDB.databases();
  //       for (const db of databases) {
  //         if (db.name) {
  //           indexedDB.deleteDatabase(db.name);
  //         }
  //       }
  //     };

  //     const clearCache = async () => {
  //       const cacheNames = await caches.keys();
  //       await Promise.all(
  //         cacheNames.map((cacheName) => {
  //           return caches.delete(cacheName);
  //         })
  //       );
  //     };

  //     deleteIndexedDB();
  //     clearCache();
  //   };

  //   clearCache();
  // }, []);

  useEffect(() => {}, [plans]);

  const updateSessiongoal = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    // setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-PORTFOLIO",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID, // Use the session ID
          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      // const sessionID: string = queryClient.getQueryData("sessionID") as string;

      // if (!sessionID) {
      //   throw new Error("Session ID not found");
      // }

      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));
      //  console.error("Error updating session:", error);
      throw error;
    }
  };

  const updateSession1 = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    //  dispatch(setLoading(true));
    // setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-PORTFOLIO",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID,

          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      const sessionID: string = queryClient.getQueryData("sessionID") as string;

      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      // setIsLoading(false);
      dispatch(setError("Error updating session:"));
      // console.error("Error updating session:", error);
      throw error;
    }
  };

  useEffect(() => {
    // console.log("Current selected Plan ");
  }, [CurrentSelectedPlan]);

  const handleFetchPlansSuccess = async (
    data: any,
    subscriptioncode: number,
    prevplancode: number
  ) => {
    if (data && data.data) {
      // Update the plans state with the fetched data
      setPlans(data.data);

      // Log the current subscription plan code
      // console.log("PlanCode:", subscriptioncode);

      // Find the matched plan using the subscriptionPlanCode

      const matchedPlan = data.data.find(
        (plan: { subscriptionPlanCode: number }) =>
          plan.subscriptionPlanCode === Number(subscriptioncode) // Convert to number if needed
      );
      setcurrentSelectedPlan(matchedPlan ? matchedPlan : []);

      const prevmatchedPlan = data.data.find(
        (plan: { subscriptionPlanCode: number }) =>
          plan.subscriptionPlanCode === Number(prevplancode) // Convert to number if needed
      );

      // console.log("CURREEEEEEEEEEE" + JSON.stringify(matchedPlan));

      // console.log("PREVVEVEVEVEVEVEVEV" + JSON.stringify(prevmatchedPlan));

      // // Log the matched plan
      // console.log("matchedPlan:", JSON.stringify(matchedPlan));

      // Update UI states
      setSubscriptionType(data.data?.billingCycle);
      setsubscriptionAmount(data.data?.oneTimeAmount);

      // Store the selected plan data in the query client
      queryClient.setQueryData("selectedPlan", data.data);

      await fetchActiveSubscriptions(
        [data.data],
        data.data,
        matchedPlan,
        prevmatchedPlan
      );

      // try {
      //   // Call to update session goal
      //   await updateSessiongoal(
      //     createdSessionData,
      //     matchedPlan,
      //     "2",
      //     "SUBSCRIPTION PLAN",
      //     "Select Package",
      //     "Select Package",
      //     "/Portfolio",
      //     "Completed"
      //   );
      // } catch (error) {
      //   setIsLoading(false);
      //   dispatch(setError("Error updating session goal:"));
      //   // console.error("Error updating session goal:", error);
      //   throw error;
      // }

      try {
        // Call to update session with the selected plan
        await updateSession1(
          createdSessionData,
          matchedPlan, // This may need to be changed based on your requirements
          "1",
          "GOALS PLANS",
          "USER SELECTED PLAN",
          "/portfolio",
          "INCOMPLETE",
          "INCOMPLETE"
        );
      } catch (error) {
        //setIsLoading(false);
        dispatch(setError("Error updating session:"));
        //console.error("Error updating session:", error);
        throw error;
      }
    } else {
      // In case of no data, reset the plans state
      setPlans([]);
    }
  };

  const handleFetchPlansError = (error: any) => {
    // console.error("Error fetching subscription plans:", error);
  };
  const fetchSubscriptionPlans = useCallback(
    async (subscriptionPlanCode: number, prevsubplanCode: number) => {
      try {
        // setIsLoading(true);
        // Modify your API call to include subscriptionPlanCode if necessary
        const response = await makeApiCall(
          API_CALL_TYPE.GET_CALL,
          GET_SUBSCRIPTION_PLANS(), // Adjust this if API requires the code
          (data) =>
            handleFetchPlansSuccess(
              data,
              subscriptionPlanCode,
              prevsubplanCode
            ),
          handleFetchPlansError,
          "",
          ""
        );
      } catch (error) {
        //    console.error("Error fetching subscription plans:", error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  // const fetchSubscriptionPlans = useCallback(async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await makeApiCall(
  //       API_CALL_TYPE.GET_CALL,
  //       GET_SUBSCRIPTION_PLANS(),
  //       handleFetchPlansSuccess,
  //       handleFetchPlansError,
  //       "",
  //       ""
  //     );
  //   } catch (error) {
  //     console.error("Error fetching subscription plans:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }, []);

  const updateSessionPlan = async (_createdSessionDataifany: any) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-PORTFOLIO",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID, // Use the session ID
          pageNumber: "1", // Page number, could be a string or a number depending on your API requirements
          pageName: "Landing", // Name of the page (e.g., "Landing")
          pageTitle: "Select Package", // Title of the page (e.g., "Select Package")
          pageDescription: "Select Package for  Rent", // Description of the page
          pageUrl: "/PORTFOLIO", // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: "Landing Page", // Status message for the page
          pageData: "{}", // Additional data related to the page, could be empty or contain serialized data
        },
      ],
    };

    try {
      const response = makeApiCall(
        API_CALL_TYPE.PUT_CALL, // Specify the API call type (GET in this case)
        UPDATE_SESSION(), // API endpoint to fetch session data
        handleUpdateSessionResponse, // Callback function to handle successful response
        handleUpdateSessionError, // Callback function to handle error response
        "", // Placeholder for formData argument
        "", // Placeholder for token argument
        sessionUpdateRequest // Pass the session ID in the body // Placeholder for body argument
      );
      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error fetching session:"));
      //console.error("Error fetching session:", error);
      throw error;
    }
  };

  const handleActiveSubscription = async (
    response: { data: SubscriptionDetail[] },
    plansData: any,
    allPlans: any,
    prev: any
  ) => {
    setIsLoading(false);

    const subscriptions = response.data;
    // console.log("ALL Plans:", JSON.stringify(plansData, null, 2));
    // console.log("FETCH ALL SUBSCRIPTIONS:", JSON.stringify(allPlans, null, 2));
    // console.log("PREVIOUS SUBSCRIPTION:", JSON.stringify(prev, null, 2));
    // console.log("Full response data:", JSON.stringify(subscriptions, null, 2));
    // console.log("Config.asaFintechCode:", Config.asaFintechCode);
    // console.log("Type of Config.asaFintechCode:", typeof Config.asaFintechCode);

    // console.log("ndfdkfkdlfj" + JSON.stringify(subscriptions));

    //   Log each subscription's asaFintechCode
    // subscriptions.forEach((sub: { asaFintechCode: any }) =>
    //   // console.log("Subscription asaFintechCode:", sub.asaFintechCode)
    // );

    // Filter subscriptions based on whether their asaFintechCode matches config.asaFintechCode
    const filteredSubscriptions = subscriptions.filter(
      (sub: { asaFintechCode: any }) => {
        const subCode = Number(sub.asaFintechCode);
        const configCode = Number(Config.asaFicode); // Ensure this is correct
        //  console.log(`Comparing ${subCode} with ${configCode}`);
        return subCode === configCode;
      }
    );

    // console.log(
    //   "Filtered Subscriptions:",
    //   JSON.stringify(filteredSubscriptions, null, 2)
    // );

    try {
      // Call to update session goal
      await updateSessiongoal(
        createdSessionData,
        filteredSubscriptions,
        "2",
        "Filtered Subscription",
        "Select Package",
        "Select Package",
        "/Portfolio",
        "Completed"
      );
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session goal:"));
      console.error("Error updating session goal:", error);
      throw error;
    }

    // Check if there are filtered subscriptions
    if (filteredSubscriptions.length === 0) {
      //       console.log("No subscriptions match the configuration code.");
      return;
    }

    const currentSubscription = filteredSubscriptions[0]; // Access the first item if the array is not empty

    // Ensure date properties are valid
    const currentDate = new Date();
    const startDate = new Date(currentSubscription.subscriptionStartDate);
    const endDate = new Date(currentSubscription.subscriptionEndDate);

    // Check if the dates are valid
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      //       console.log("Invalid start or end date.");
      return;
    }

    // Convert Date objects to milliseconds
    // Convert Date objects to milliseconds
    const currentDateMs = currentDate.getTime();
    const startDateMs = startDate.getTime();
    const endDateMs = endDate.getTime();

    // Calculate total days in the subscription period
    const totalDaysInSubscription = Math.ceil(
      (endDateMs - startDateMs) / (1000 * 60 * 60 * 24)
    );

    // console.log("CUREREREREERERRERERE" + currentSubscription.recurringAmount);

    // Calculate daily cost
    const dailyCost =
      currentSubscription.recurringAmount / totalDaysInSubscription;

    // Calculate remaining days in the subscription
    const daysLeft = Math.ceil(
      (endDateMs - currentDateMs) / (1000 * 60 * 60 * 24)
    );

    // console.log("DAYSYSYYSYSYSYSYS" + daysLeft);
    // Check if remaining days are valid
    if (daysLeft < 0) {
      console.error("The subscription has already ended.");
      return;
    }

    // Output the results
    // console.log(
    //   `Total days in current subscription: ${totalDaysInSubscription}`
    // );
    // console.log(`Daily cost: ${dailyCost.toFixed(2)}`); // Format to 2 decimal places
    // //     console.log(`Days left in current subscription: ${daysLeft}`);
    // console.log(
    //   `Remaining value of subscription: ${(daysLeft * dailyCost).toFixed(2)}`
    // ); // Remaining value calculation

    const proratedAmountNew = daysLeft * dailyCost; // Compute prorated amount based on remaining days
    // Calculate remaining days

    // console.log("Prorated Amount::" + proratedAmountNew);
    // console.log("ALLSLLLFF" + JSON.stringify);

    // Daily cost of the current plan
    const currentPlanCost = allPlans.recurringAmount;
    // console.log("currentPlanCost :::" + currentPlanCost);

    // Cost of the new plan
    const newPlanCost = allPlans.recurringAmount;
    const proratedDifference = newPlanCost - proratedAmountNew; // Ensure both are numbers
    const formattedProratedAmountNew = proratedAmountNew.toFixed(2);
    const formattedProratedDifference = proratedDifference.toFixed(2);

    // Update state with formatted values
    setFormattedProratedAmountNew(formattedProratedAmountNew);
    setFormattedProratedDifference(formattedProratedDifference);

    // console.log("Formatted Prorated Amount: " + formattedProratedAmountNew);
    // console.log(
    //   "Formatted Prorated Difference: " + formattedProratedDifference
    // );

    // console.log("FormatttedProratedAmount" + formattedProratedAmountNew);
    // console.log("FormatttedProratedDifference" + formattedProratedDifference);

    // Ensure CurrentSelectedPlan is correctly populated
    // console.log(
    //   "Current Selected PLAN:",
    //   CurrentSelectedPlan
    //     ? JSON.stringify(CurrentSelectedPlan[0])
    //     : "No current plan selected"
    // );

    const currentPlans = Array.isArray(plansData[0]) ? plansData[0] : plansData; // Flatten if needed
    // console.log("Current Plans:", JSON.stringify(currentPlans, null, 2));

    const filteredSubscriptions1 = currentPlans.filter(
      (plan: { subscriptionPlanCode: any }) => {
        const planId = plan.subscriptionPlanCode; // Convert to string
        return filteredSubscriptions.some(
          (sub: { subscriptionPlanCode: any }) =>
            sub.subscriptionPlanCode === planId // Compare as strings
        );
      }
    );

    //     const filteredSubscriptions1 = filteredSubscriptions.filter(sub =>
    //   currentPlans.some((plan: { subscriptionPlanCode: number; }) => plan.subscriptionPlanCode === sub.subscriptionPlanCode)
    // );

    // console.log(
    //   "Filtered Plans:",
    //   JSON.stringify(filteredSubscriptions1, null, 2)
    // );

    if (!CurrentSelectedPlan || !filteredSubscriptions1.length) {
      // console.log(
      //   "Cannot determine the upgrade or downgrade. Either currentPlan or previousPlan is missing."
      // );
      return;
    }

    // console.log("Prev....." + JSON.stringify(filteredSubscriptions1));

    const currentPlan = allPlans;
    const previousPlan =
      filteredSubscriptions1.length > 0 ? filteredSubscriptions1[0] : null;

    setscurrentplan(currentPlan);
    setPreviousSeletedPlan(previousPlan);

    // console.log("Current Plan selected ====:::" + JSON.stringify(scurrentPlan));
    // console.log(
    //   "Previous Plan selected.=====:::" + JSON.stringify(prevSelectedPlan)
    // );

    try {
      // Call to update session goal
      await updateSessiongoal(
        createdSessionData,
        currentPlan,
        "10",
        "Current Plan",
        "Select Package",
        "Select Package",
        "/Portfolio",
        "Completed"
      );
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session goal:"));
      console.error("Error updating session goal:", error);
      throw error;
    }

    try {
      // Call to update session goal
      await updateSessiongoal(
        createdSessionData,
        previousPlan,
        "11",
        "Previous Plan",
        "Select Package",
        "Select Package",
        "/Portfolio",
        "Completed"
      );
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session goal:"));
      console.error("Error updating session goal:", error);
      throw error;
    }

    // Access the first plan from the array
    if (currentPlan && previousPlan) {
      const currentAmount = currentPlan.recurringAmount; // Access recurringAmount from the selected plan
      const previousAmount = previousPlan.recurringAmount;

      // console.log(`Current Plan Amount: ${currentAmount}`);
      // console.log(`Previous Plan Amount: ${previousAmount}`);

      if (currentAmount > previousAmount) {
        console.log("The plan has been upgraded.");
      } else if (currentAmount < previousAmount) {
        console.log("The plan has been downgraded.");
      } else {
        console.log("The plan amount remains unchanged.");
      }
    } else {
      console.log(
        "Cannot determine the upgrade or downgrade. Either currentPlan or previousPlan is missing."
      );
    }
  };

  const handleSessionResponse = async (response: any) => {
    setIsLoading(false);

    if (response && response.data && response.data.asaSessionCode) {
      // Handle successful session response
      const sessionPages = response.data.sessionPages;
      let subscriptionPlanCode = null;
      let previousSubscriptionPlanCode = null;

      for (let page of sessionPages) {
        if (page.pageNumber === 99) {
          const pageData = JSON.parse(page.pageData);
          subscriptionPlanCode = pageData.subscriptionPlanCode;
          previousSubscriptionPlanCode = pageData.prevSubscriptionPlanCode;
          // console.log("Subscription Plan Code:", subscriptionPlanCode);
          setsubscriptionPlanCode(subscriptionPlanCode);
          setprevsubscriptionPlanCode(previousSubscriptionPlanCode);
        }
      }

      queryClient.setQueryData("sessionData", response);
      setcreatedSessionData(response?.data);
      showCustomToast(response?.message, false);

      // Fetch data in parallel
      await Promise.all([
        fetchSubscriptionPlans(
          subscriptionPlanCode,
          previousSubscriptionPlanCode
        ),
        fetchBillingAddresses(),
        fetchPaymentMethods(),

        updateSessionPlan(response?.data),
      ]);
    } else if (response?.status === 400) {
      // Handle the invalid session error
      // console.error("Invalid session error:", response.message);
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      setIsLoading(false);
      // Session ID not found in the response
      // console.log("Session ID not found in the response");
    }
  };

  const getSession = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL, // Specify the API call type (GET in this case)
        SAVE_SESSION(Config.sessionID), // API endpoint to fetch session data
        handleSessionResponse, // Callback function to handle successful response
        handleSessionError, // Callback function to handle error response
        "", // Placeholder for formData argument
        "", // Placeholder for token argument
        "" // Placeholder for body argument
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching session:", error);
      if (!getSessionCalled) {
        setGetSessionCalled(true);
        getSession();
      }
      throw error;
    }
  }, []);

  useEffect(() => {
    //debugger;

    const currentUrl = new URL(window.location.href);

    // const currentUrl = new URL(Config.BROWSER_URL);

    // if (
    //   currentUrl.pathname === "/portfoliopilot" &&
    //   !currentUrl.searchParams.toString()
    // ) {
    //   // Redirect to another screen
    //   navigate("/error"); // Change this to your desired route
    //   return;
    // }

    const sessionID = currentUrl.searchParams.get("sessionid");
    const asaConsumerCode = currentUrl.searchParams.get("asaConsumerCode");
    const versionCode = currentUrl.searchParams.get("version");
    Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");
    const token = currentUrl.searchParams.get("token");
    const asaFicode = currentUrl.searchParams.get("asaFintechCode");
    // const asaFintechCode = currentUrl.searchParams.get("asaFintechCode");

    if (asaFicode !== null) {
      const parsedFicode = parseInt(asaFicode, 10);
      if (!isNaN(parsedFicode)) {
        Config.asaFicode = parsedFicode;
      } else {
        // Handle case where asaFicode is not a valid number
        console.error("Invalid asaFicode:", asaFicode);
      }
    }
    let decodedLink: string | null = null;
    const encodedLink = currentUrl.searchParams.get("dynamicLink");
    if (encodedLink !== null) {
      decodedLink = decodeURIComponent(encodedLink); // Assign decodedLink inside the if block
      //   dispatch(setDynamicLink(decodedLink)) // Dispatch action to save dynamic link
      queryClient.setQueryData("dynamicLink", decodedLink);
    } else {
      //console.error("encodedLink is nulldsfdsfdsf");
    } // Declare decodedLink outside the if block
    if (sessionID && asaConsumerCode) {
      // Update Config object with session ID and subscription key
      Config.sessionID = sessionID;
      Config.asaConsumerCode = parseInt(asaConsumerCode);
      // Save session ID to React Query cache
      queryClient.setQueryData("sessionID", sessionID);
      setSessionID(sessionID);
      dispatch(setSessionIDStore(sessionID));
      dispatch(setAsaConsumerCode(Config.asaConsumerCode));

      // if (sessionID) {
      // If sessionID is not set, call getSession to fetch it
      setSessionFetched(true);
      getSession();

      // }
    } else {
      if (UpdatedLocalData) {
        const sessionPages = UpdatedLocalData?.data?.sessionPages;

        if (!sessionPages || sessionPages.length === 0) {
          // If sessionPages array is empty or not found, fetch PaymentMethodList from API
          // fetchPaymentMethods();
          // fetchBillingAddresses();
          return;
        }

        sessionPages.forEach((page: { pageName: string; pageData: string }) => {
          try {
            if (page.pageName === "SUBSCRIPTION PLAN") {
              const parsedPageData = JSON.parse(
                page?.pageData.replace(/\\/g, "")
              );

              if (parsedPageData) {
                if (parsedPageData?.asaFintechCode !== null) {
                  const parsedFicode = parseInt(
                    parsedPageData?.asaFintechCode,
                    10
                  );
                  if (!isNaN(parsedFicode)) {
                    Config.asaFicode = parsedFicode;
                  } else {
                    // Handle case where asaFicode is not a valid number
                    console.error("Invalid asaFicode:", asaFicode);
                  }
                }
              }
            }
          } catch (error) {
            console.error(`Error processing page '${page.pageName}':`, error);
          }
        });

        Config.sessionID = UpdatedLocalData?.data?.asaSessionCode;
        Config.asaConsumerCode = parseInt(
          UpdatedLocalData?.data?.asaConsumerCode
        );

        const versionCode = UpdatedLocalData?.data?.version;
        Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");

        queryClient.setQueryData("sessionID", Config.sessionID);

        setSessionID(Config.sessionID);
        dispatch(setSessionIDStore(UpdatedLocalData?.data?.asaSessionCode));
        dispatch(setAsaConsumerCode(Config.asaConsumerCode));

        // if (sessionID) {
        // If sessionID is not set, call getSession to fetch it
        setSessionFetched(true);
        getSession();
      }

      //navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
    }
    // }, [sessionID, Config.asaConsumerCode, sessionFetched]); // Run whenever sessionID changes
  }, []); // Run whenever sessionID changes

  // useEffect(() => {
  //   if (UpdatedLocalData) {
  //     const sessionPages = UpdatedLocalData?.data?.sessionPages;

  //     if (!sessionPages || sessionPages.length === 0) {
  //       // If sessionPages array is empty or not found, fetch PaymentMethodList from API
  //       // fetchPaymentMethods();
  //       // fetchBillingAddresses();
  //       return;
  //     }
  //     sessionPages.forEach((page: { pageName: string; pageData: string }) => {
  //       try {
  //         if (page.pageName === "GOALS PLANS") {
  //           const parsedPageData = JSON.parse(
  //             page?.pageData.replace(/\\/g, "")
  //           );

  //           if (parsedPageData) {
  //             if (parsedPageData?.asaFintechCode !== null) {
  //               const parsedFicode = parseInt(
  //                 parsedPageData?.asaFintechCode,
  //                 10
  //               );
  //               if (!isNaN(parsedFicode)) {
  //                 Config.asaFicode = parsedFicode;
  //               } else {
  //                 // Handle case where asaFicode is not a valid number
  //               }
  //             }
  //           }
  //         }
  //       } catch (error) {
  //         console.error(`Error processing page '${page.pageName}':`, error);
  //       }
  //     });

  //     // Config.sessionID = UpdatedLocalData?.data?.asaSessionCode;
  //     // Config.asaConsumerCode = parseInt(
  //     //   UpdatedLocalData?.data?.asaConsumerCode
  //     // );
  //     // const versionCode = UpdatedLocalData?.data?.version;
  //     // Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");
  //     // queryClient.setQueryData("sessionID", Config.sessionID);
  //   }

  //   if (selectedPaymentIndex !== null) {
  //     const selectedPaymentMethod = paymentMethods[selectedPaymentIndex];
  //     const storedCVV =
  //       paymentCVVs[selectedPaymentMethod?.paymentMethodId] || "";
  //     setCVV(storedCVV);
  //   }
  // }, [selectedPaymentIndex, paymentMethods, paymentCVVs]);

  useEffect(() => {
    FetchConsumer();
    // const isFirstVisit = localStorage.getItem("isFirstVisit");

    // if (!isFirstVisit) {
    //   // If it's the user's first visit, set the flag in local storage
    //   localStorage.setItem("isFirstVisit", "true");
    //   // Exit early if it's the user's first visit
    //   return;
    // }

    if (!UpdatedLocalData) {
      // If UpdatedLocalData is empty or null, fetch PaymentMethodList and BillingAddressList from API
      // fetchPaymentMethods();
      // fetchBillingAddresses();
      return; // Exit early if there's no UpdatedLocalData
    }

    // Extract sessionPages from UpdatedLocalData
    const sessionPages = UpdatedLocalData.data.sessionPages;

    if (!sessionPages || sessionPages.length === 0) {
      // If sessionPages array is empty or not found, fetch PaymentMethodList from API
      // fetchPaymentMethods();
      // fetchBillingAddresses();
      return;
    }
    let foundPaymentMethods = false;
    let foundBillAddress = false;
    let selectedPaymentMethod = false;

    sessionPages.forEach((page: { pageName: string; pageData: string }) => {
      try {
        if (page.pageName === "GOALS PLANS") {
          const parsedPageData: SubscriptionPlanData = JSON.parse(
            page.pageData.replace(/\\/g, "")
          );
          if (parsedPageData) {
            const subplan: SubscriptionPlan = {
              pageName: page.pageName,
              pageData: parsedPageData,
            };
            setSubscriptionPlanData(subplan);
            const dynamicLink = parsedPageData?.billingCycle;
            setDynamicLink(dynamicLink);
          }
        } else if (page.pageName === "Current Plan") {
          const parsedPageData: SubscriptionPlanData = JSON.parse(
            page.pageData.replace(/\\/g, "")
          );
          const currentPlans = JSON.parse(page.pageData);
          setActivePlanData(currentPlans);

          //           console.log("PARSED CURRENT PALNS0.." + JSON.stringify(currentPlans));
        } else if (page.pageName === "Previous Plan") {
          const parsedPageData: SubscriptionPlanData = JSON.parse(
            page.pageData.replace(/\\/g, "")
          );
          const currentPlans = JSON.parse(page.pageData);
          setPreviousPlanData(currentPlans);

          //           console.log("PARSED CURRENT PALNS0.." + JSON.stringify(currentPlans));
        } else if (page.pageName === "Filtered Subscription") {
          const parsedPageData: SubscriptionPlanData = JSON.parse(
            page.pageData.replace(/\\/g, "")
          );
          const currentPlans = JSON.parse(page.pageData);
          setFilteredPlansData(currentPlans);

          //           console.log("PARSED CURRENT PALNS0.." + JSON.stringify(currentPlans));
        } else if (page.pageName === "PaymentMethodList") {
          foundPaymentMethods = true;
          const paymentMethodsData = JSON.parse(page.pageData);
          if (
            Array.isArray(paymentMethodsData) &&
            paymentMethodsData.length > 0
          ) {
            //

            setPaymentMethods(paymentMethodsData);

            if (paymentMethodsData.length === 0) {
              //   dispatch(setSelectedPaymentMethod(null));
              // fetchPaymentMethods();
            } else {
              // setPaymentMethods(paymentMethodsData);

              if (
                getSelectedPaymentfromStore &&
                Object.keys(getSelectedPaymentfromStore).length > 0
              ) {
                const selectedPaymentMethod = getSelectedPaymentfromStore;
                const cvv =
                  paymentCVVs[selectedPaymentMethod.paymentMethodId] || "";
                setCVV(cvv);
                dispatch(setSelectedPaymentMethod(selectedPaymentMethod));

                setIsDropdownOpen(false);
              } else {
                setIsDropdownOpen(true);
                // dispatch(setSelectedPaymentMethod(null));
              }
            }
          }
        } else if (page.pageName === "SelectedPaymentMethod") {
          selectedPaymentMethod = true;

          const selectedPaymentMethodData = JSON.parse(page.pageData);
          //console.log("PAYMENT" + JSON.stringify(selectedPaymentMethodData));

          // Check if the data is an object and not an array
          if (
            selectedPaymentMethodData &&
            typeof selectedPaymentMethodData === "object"
          ) {
            setSelectedPaymentMethod(selectedPaymentMethodData);
            dispatch(setSelectedPaymentMethod(selectedPaymentMethodData));

            // Assuming empty data means null or similar
            if (!selectedPaymentMethodData.paymentMethodId) {
              // Handle empty payment method case
              // dispatch(setSelectedPaymentMethod(null));
              // fetchPaymentMethods(); // Uncomment if needed
            } else {
              // Handle non-empty payment method case
              if (
                getSelectedPaymentfromStore &&
                Object.keys(getSelectedPaymentfromStore).length > 0
              ) {
                setIsDropdownOpen(false);
              } else {
                setIsDropdownOpen(true);
                // dispatch(setSelectedPaymentMethod(null));
              }
            }
          }
        } else if (page.pageName === "BillAddressList") {
          foundBillAddress = true;
          const BillAddressData = JSON.parse(page.pageData);

          if (Array.isArray(BillAddressData) && BillAddressData.length > 0) {
            setBillingAddresses(BillAddressData);

            if (
              getSelectedBillingfromStore &&
              Object.keys(getSelectedBillingfromStore).length > 0
            ) {
              setIsBillingDropdownOpen(false);
            } else {
              setIsBillingDropdownOpen(true);
            }
          } else {
            dispatch(setSelectedBillingAddress(null));
            setIsBillingDropdownOpen(true);
          }
        }
      } catch (error) {
        console.error(`Error processing page '${page.pageName}':`, error);
      }
    });

    // If BillAddressList data is not found, fetch it from the API
    if (!foundBillAddress) {
      // fetchBillingAddresses();
      // dispatch(setSelectedPaymentMethod(null));
    }

    // If PaymentMethodList data is not found, fetch it from the API
    if (!foundPaymentMethods) {
      setIsDropdownOpen(true);
      // fetchPaymentMethods();
    }

    if (!selectedPaymentMethod) {
      //   console.log("dsdsfjdfdf" + selectedPaymentMethod);
      setIsDropdownOpen(true);
      dispatch(setSelectedPaymentMethod(null));
    }
  }, [UpdatedLocalData, dispatch]);

  useEffect(() => {
    // console.log("SubscriptionPlanData:", subscriptionPlanData);
  }, [subscriptionPlanData]);

  useEffect(() => {
    // console.log("getSelectedPaymentfromStore:", getSelectedPaymentfromStore);
  }, [getSelectedPaymentfromStore]);
  useEffect(() => {
    // console.log("getSelectedPaymentfromStore:", getSelectedPaymentfromStore);
  }, [getSelectedBillingfromStore]);

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        POST_PAYMENT_METHOD(),
        handlePaymentMethodsResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const fetchActiveSubscriptions = useCallback(
    async (data: any, plansdata: any, allPlans: any, prevmatchedPlan: any) => {
      try {
        setIsLoading(true);
        // Modify your API call to include subscriptionPlanCode if necessary
        const response = await makeApiCall(
          API_CALL_TYPE.GET_CALL,
          GET_ACTIVE_SUBSCRIPTION(), // Adjust this if API requires the code
          (data) =>
            handleActiveSubscription(
              data,
              plansdata,
              allPlans,
              prevmatchedPlan
            ),
          handleACtiveSubscriptionError,
          "",
          ""
        );
      } catch (error) {
        //    console.error("Error fetching subscription plans:", error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  // const fetchActiveSubscriptions = useCallback(async (allPlans:any, prevmatchedPlan:any) => {
  //   try {
  //     const response = makeApiCall(
  //       API_CALL_TYPE.GET_CALL,
  //       GET_ACTIVE_SUBSCRIPTION(),
  //       handleActiveSubscription(allPlans,prevmatchedPlan),
  //       handleACtiveSubscriptionError,
  //       "",
  //       ""
  //     );
  //   } catch (error) {
  //     setIsLoading(false); // Stop loading state on error
  //   }
  // }, []);

  const FetchConsumer = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_CONSUMER_ADDRESS(),
        handleConsumerAddress,
        handleCOnsumerError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handleDeletePaymentMethod = async (index: number) => {
    setDeletePaymentIndex(index);
    setShowConfirmDialog(true);
  };

  const handleDeletePaymentMethodCards = async (index: number) => {
    setShowConfirmDialogPayment(true);
    setDeletePaymentIndexCard(index);
  };
  const toggleBillingDropdown = () => {
    setIsBillingDropdownOpen(!isBillingDropdownOpen);
  };

  const fetchBillingAddresses = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_BILLING_ADDRESSES(),
        handleBillingAddressesResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handleReviewAndPayClick = () => {
    // Handle click action, such as navigating to another screen or performing some other action
    const title =
      subscriptionPlanData && subscriptionPlanData.pageData.subscriptionTitle;

    if (title === "Gold Subscription") {
      navigate("/guac");
    } else if (title === "Gold" || title === "Platinum") {
      navigate("/PortfolioPilot");
    } else {
      navigate("/onegoal");
    }
  };

  const handleConsumerResponse = async (response: any) => {
    // Handle success response
    setIsLoading(false);
    showCustomToast("Consumer Address deleted successfully", false);
  };

  const handleConsumerError = (error: any) => {
    setIsLoading(false);
    showCustomToast(error.message, true);
  };

  const updateSessionDeletion = async (
    updatedSessionData: SessionUpdateRequest,
    session_if_any: any
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);

    try {
      let currentSessionData = UpdatedLocalData || {};
      let sessionPages = [...(currentSessionData?.data?.sessionPages || [])];

      // Check if the "BillAddressList" page exists
      const existingPageIndex = sessionPages.findIndex(
        (page: any) => page.pageName === "BillAddressList"
      );

      if (existingPageIndex !== -1) {
        let existingPageData = sessionPages[existingPageIndex].pageData;
        let pageDataArray = [];

        try {
          pageDataArray = JSON.parse(existingPageData);
        } catch (error) {}

        if (Array.isArray(pageDataArray)) {
          // Remove the specific record if found
          pageDataArray = pageDataArray.filter(
            (item: any) =>
              item.consumerAddressId !== session_if_any.consumerAddressId
          );

          // Update the session page with the modified data
          sessionPages[existingPageIndex] = {
            ...sessionPages[existingPageIndex],
            pageData: JSON.stringify(pageDataArray), // Serialize the updated data
          };
        } else {
          // If pageData was not an array, reset to an empty array
          sessionPages[existingPageIndex] = {
            ...sessionPages[existingPageIndex],
            pageData: JSON.stringify([]), // Set to empty array as there were no valid records
          };
        }
      } else {
        // If the page doesn't exist, there’s nothing to delete
        return; // Exit early since there’s nothing to delete
      }

      const sessionUpdateRequest: SessionUpdateRequest = {
        asaSessionCode: Config.sessionID,
        asaConsumerCode: Config.asaConsumerCode,
        version: Config.X_ASA_version.toString(),
        sessionName: "ASAVAULT-PORTFOLIO",
        pageCount: "3",
        status: "Incomplete",
        statusMessage: "INCOMPLETE",
        sessionPages: sessionPages,
      };

      try {
        const response = await makeApiCall(
          API_CALL_TYPE.PUT_CALL,
          UPDATE_SESSION(),
          handleUpdateSessionResponse,
          handleUpdateSessionError,
          "",
          "",
          sessionUpdateRequest
        );

        return response;
      } catch (error) {
        setIsLoading(false);
        dispatch(setError("Error updating session:"));
        throw error;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    if (deletePaymentIndex !== null) {
      try {
        const billingAddressToDelete = billingAddresses[deletePaymentIndex];
        setIsLoading(true);

        const requestData = [
          {
            consumerAddressId: billingAddressToDelete.consumerAddressId,
            address1: billingAddressToDelete.address1,
            city: billingAddressToDelete.city,
            state: billingAddressToDelete.state,
            zipCode: billingAddressToDelete.zipCode,
            firstName: billingAddressToDelete.firstName,
            lastName: billingAddressToDelete.lastName,
            country: billingAddressToDelete.country,
            isCurrent: false,
            isActive: false,
            moveoutDate: null,
            isBilling: true,
            isPrimary: false,
            isShipping: false,
            addressType: null,
          },
        ];

        const response = await makeApiCall(
          API_CALL_TYPE.POST_CALL,
          POST_CONSUMER_ADDRESS(),
          handleConsumerResponse,
          handleConsumerError,
          "",
          "",
          requestData
        );

        try {
          let dataToUpdate; // Declare variable to hold the data to be updated

          // Check if UpdatedLocalData contains data and use it if available
          if (UpdatedLocalData) {
            dataToUpdate = UpdatedLocalData;
          } else {
            dataToUpdate = updatedsessionData;
          }

          if (dataToUpdate) {
            // Set loading state to true before making the API call
            setIsLoading(true);
            //    await updateSession(dataToUpdate);

            await updateSessionDeletion(dataToUpdate, billingAddressToDelete);

            // Navigate to payment screen after successful API call
            // navigate("/payment");
          } else {
            console.error("No session data available to update.");
          }
        } catch (error) {
          setIsLoading(false);

          // Handle error here
        } finally {
          // Set loading state to false after API call is completed (whether success or error)
        }

        //  await updateSessionDeletion(billingAddressToDelete);

        const updatedBillingAddresses = [...billingAddresses];
        if (
          getSelectedBillingfromStore &&
          getSelectedBillingfromStore.consumerAddressId ===
            billingAddressToDelete.consumerAddressId
        ) {
          dispatch(setSelectedBillingAddress(null));
        }

        updatedBillingAddresses.splice(deletePaymentIndex, 1);
        setBillingAddresses(updatedBillingAddresses);
        setDeletePaymentIndex(null);
        setShowConfirmDialog(false);
      } catch (error) {
        console.error("Error deleting billing address:", error);
      }
    }
  };

  const handleDeletePaymentResponse = async (response: any) => {
    // Handle success response
    setIsLoading(false);
    showCustomToast(response.message, false);
    // navigate("/PortfolioPayment");
  };

  const handlePaymentMethodError = (error: any) => {
    setIsLoading(false);
    showCustomToast(error.message, true);
  };

  const handleConfirmDeletePaymet = async () => {
    if (deletePaymentIndexCard !== null) {
      try {
        try {
          // Prepare the data for deletion
          const paymentMethodToDelete = paymentMethods[deletePaymentIndexCard];

          // Headers including asaPaymentMethodCode

          // Make API call to delete payment method with headers
          makeApiCall(
            API_CALL_TYPE.DELETE_CALL, // Using DELETE method
            POST_PAYMENT_METHOD(), // Endpoint URL for DELETE
            handleDeletePaymentResponse, // Success callback
            handlePaymentMethodError, // Error callback
            null, // formData (not used in this case)
            "", // token (not used in this case)
            {
              asaPaymentMethodCode: paymentMethodToDelete?.asaPaymentMethodCode,
            } // Body for DELETE
          );

          const updatedBillingAddresses = [...paymentMethods];
          updatedBillingAddresses.splice(deletePaymentIndexCard, 1);
          setPaymentMethods(updatedBillingAddresses);
          setDeletePaymentIndexCard(null);
          setShowConfirmDialogPayment(false);

          // If successful, update local state
          // const updatedPaymentMethods = paymentMethods.filter(
          //   (_, index) => index !== deletePaymentIndexCard
          // );
          // setPaymentMethods(updatedPaymentMethods);
          // setDeletePaymentIndexCard(null);
          // setShowConfirmDialogPayment(false);
        } catch (error) {
          // Handle error scenarios
        }
        // Perform deletion logic here, e.g., calling API to delete the billing address
      } catch (error) {
        console.error("Error deleting payment Address address:", error);
        // Handle error scenarios
      }
    }
  };

  const handleEditPaymentMethod = (index: number) => {
    const paymentMethodsIn = paymentMethods[index];

    navigate("/PaymentOneGoal", { state: { payment: paymentMethodsIn } });
  };

  const handleEditBillingAddress = (index: number) => {
    const selectedAddress = billingAddresses[index];

    navigate("/AddressPortfolio", { state: { address: selectedAddress } });
  };

  const handlePaymentMethodsResponse = async (data: any) => {
    const formattedPaymentMethods: PaymentMethod[] = data.data.map(
      (method: any) => ({
        paymentMethodId: method.paymentMethodId,
        asaPaymentMethodCode: method.asaPaymentMethodCode,
        isDefault: method.isDefault,
        paymentMethodName: method.paymentMethodName,
        paymentType: method.paymentType,
        routingNumber: method.routingNumber,
        last4DigitsOfAccountNumber: method.last4DigitsOfAccountNumber,
        bankName: method.bankName,
        cardType: method.cardType,
        cardNumber: method.cardNumber,
        accountNumber: method.accountNumber,
        last4DigitsOfCardNumber: method.last4DigitsOfCardNumber,
        firstName: method.firstName,
        middleName: method.middleName,
        lastName: method.lastName,
        cardHolderName: method.cardHolderName,
        expirationYear: method.expirationYear,
        expirationMonth: method.expirationMonth,
      })
    );

    setPaymentMethods(formattedPaymentMethods);
    if (formattedPaymentMethods.length == 0) {
      setIsDropdownOpen(true);
    }
    //  setIsDropdownOpen(!isDropdownOpen);

    if (
      getSelectedPaymentfromStore &&
      Object.keys(getSelectedPaymentfromStore).length > 0
    ) {
      setIsDropdownOpen(false);
    } else {
      setIsDropdownOpen(true);
      dispatch(setSelectedPaymentMethod(null));
    }

    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        // Set loading state to true before making the API call

        await updateSession(
          dataToUpdate,
          formattedPaymentMethods,
          "3",
          "PaymentMethodList",
          "PaymentMethodList"
        );
        // Navigate to payment screen after successful API call
        // navigate("/payment");
      } else {
      }
    } catch (error) {
      setIsLoading(false);

      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }
    setShowPaymentMethods(true); // Show payment methods after data is fetched
    setIsLoading(false); // Stop loading state
  };

  const handleBillingAddressesResponse = async (data: any) => {
    const formattedBillingAddresses: BillingAddress[] = data?.data
      .filter((address: any) => address.isBilling === true)
      .map((address: any) => ({
        consumerAddressId: address.consumerAddressId,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        firstName: address.firstName,
        lastName: address.lastName,
        country: address.country,
      }));

    if (formattedBillingAddresses.length) {
      setBillingAddresses(formattedBillingAddresses);
    }

    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        // Set loading state to true before making the API call
        setIsLoading(true);

        await updateSession(
          dataToUpdate,
          formattedBillingAddresses,
          "7",
          "BillAddressList",
          "BillAddressList"
        );

        // Navigate to payment screen after successful API call
        // navigate("/payment");
      } else {
        //   console.error("No session data available to update.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating session:", error);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }

    setIsLoading(false); // Stop loading state
  };

  const handleApiError = (error: any) => {
    setIsLoading(false); // Stop loading state on error
  };

  const handleConsumerAddress = (data: any) => {
    dispatch(setCOnsumerAdress(data));
    setIsLoading(false); // Stop loading state on error
  };

  const handleCOnsumerError = (error: any) => {
    console.error("API Error: ", error);
  };

  const togglePaymentMethods = () => {
    //navigate("/AddMethods");

    setIsDropdownOpen(!isDropdownOpen);
    setShowPaymentMethods(!showPaymentMethods);
  };

  const onAddPaymentMethod = () => {
    navigate("/AddMethodsPortfolio");

    // setIsDropdownOpen(!isDropdownOpen);
    // setShowPaymentMethods(!showPaymentMethods);
  };

  const toggleBillingAddress = () => {
    //navigate("/AddMethods");

    setIsBillingDropdownOpen(!isBillingDropdownOpen);
    setShowPBillAddress(!showbillAddress);
  };

  const addBilling = () => {
    navigate("/AddressPortfolio");

    // setIsDropdownOpen(!isDropdownOpen);
    // setShowPaymentMethods(!showPaymentMethods);
  };

  const getImageSource = (type: string) => {
    switch (type) {
      case "ACH":
        return check_everyday;
      case "Bank":
        return check_everyday; // Assuming Bank uses the same image as check_everyday
      case "Visa":
        return visa;
      case "CC":
        return city_double_cash;
      default:
        return pwa_image;
    }
  };

  const handleUpdateSessionResponse = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);
      // navigate("/PortfolioPayment");
      // Dispatch actions, update state, etc.
    }
  };

  // Handling Update Session Error

  const handleUpdateSessionError = (_error: any) => {
    setIsLoading(false);
    dispatch(setLoading(false));
  };

  const updateSession = async (
    updatedSessionData: SessionUpdateRequest,
    session_if_any: any,
    pageNumber: string,
    pageName: string,

    pageTitle: string
  ) => {
    dispatch(setLoading(true));
    //setIsLoading(true);

    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-PORTFOLIO",
      pageCount: session_if_any.pageCount,
      status: "Incomplete", // Ensure the status field is a string,
      statusMessage: "INCOMPLETE",
      sessionPages: [
        ...(Array.isArray(updatedSessionData.sessionPages)
          ? updatedSessionData.sessionPages
          : []),
        {
          asaSessionCode: UpdatedLocalData?.data?.asaSessionCode,

          pageNumber: pageNumber, // Assuming this is the second page
          pageName: pageName,
          pageTitle: pageTitle,

          pageDescription: pageTitle,
          pageUrl: "/PaymentList",
          pageStatus: "Completed",
          pageStatusMessage: "PaymentList",
          pageData: JSON.stringify(session_if_any), // Serialize the selected plan data
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        sessionUpdateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };

  const handlePaymentSelection = async (index: number, type: string) => {
    setSelectedPaymentType(type);

    setSelectedPaymentIndex(index);
    setCVVEntered(false); // Reset CVV entered state

    if (type === "CC") {
    } else {
      setIsDropdownOpen(false);
    }

    if (type === "ACH") {
    } else {
      setIsDropdownOpen(false);

      const selectedPaymentMethod = paymentMethods[index];
      const cvv = paymentCVVs[selectedPaymentMethod?.paymentMethodId] || "";
      setCVV(cvv);

      //dispatch(setSelectedPaymentMethod(selectedPaymentMethod));
    }

    try {
      const selectedPaymentMethod = paymentMethods[index];
      const cvv = paymentCVVs[selectedPaymentMethod?.paymentMethodId] || "";
      setCVV(cvv);

      dispatch(setSelectedPaymentMethod(selectedPaymentMethod));

      // const selectedPaymentMethod = paymentMethods[index];
      // dispatch(setSelectedPaymentMethod(selectedPaymentMethod));

      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        // Set loading state to true before making the API call
        //setIsLoading(true);

        await updateSession(
          dataToUpdate,
          selectedPaymentMethod,
          "5",
          "SelectedPaymentMethod",
          "SelectedPaymentMethod"
        );
      } else {
        console.error("No session data available to update.");
      }
    } catch (error) {
      console.error(
        "Error updating session with new billing address and payment method:",
        error
      );
    }
  };
  // const handlePaymentSelection = async (index: number, type: string) => {
  //   setSelectedPaymentType(type);
  //   setSelectedPaymentIndex(index);
  //   setCVVEntered(false);

  //   // Assuming paymentMethods[index] is valid
  //   const selectedPaymentMethod = paymentMethods[index];
  //   const cvv = paymentCVVs[selectedPaymentMethod?.paymentMethodId] || "";
  //   setCVV(cvv);

  //   dispatch(setSelectedPaymentMethod(selectedPaymentMethod));

  //   let dataToUpdate = UpdatedLocalData || updatedsessionData;

  //   if (dataToUpdate) {
  //     try {
  //       await updateSession(
  //         dataToUpdate,
  //         selectedPaymentMethod,
  //         "5",
  //         "SelectedPaymentMethod",
  //         "SelectedPaymentMethod"
  //       );
  //     } catch (error) {
  //       console.error("Error updating session:", error);
  //     }
  //   } else {
  //     console.error("No session data available to update.");
  //   }

  //   setIsBillingDropdownOpen(type === "CC");

  //   setIsDropdownOpen(type !== "ACH"); // Manage dropdown visibility based on type
  // };

  const handleCVVBlur = () => {
    if (cvv.length === 3) {
      setCVVEntered(true);
      // Proceed with your next steps if needed
    }
  };

  const handleCVVClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleBackClick = () => {
    // const title =
    //   subscriptionPlanData && subscriptionPlanData.pageData.subscriptionTitle;

    // if (title === "Gold Subscription") {
    //   navigate("/guac");
    // } else if (title === "Gold" || title === "Platinum") {
    //   navigate("/PortfolioPilot");
    // } else {
    //   navigate("/onegoal");
    // }

    if (UpdatedLocalData) {
      const sessionPages = UpdatedLocalData.data.sessionPages;
      let backActionDynamicLink = null;

      for (let i = 0; i < sessionPages.length; i++) {
        if (sessionPages[i].pageNumber === 0) {
          const pageData = JSON.parse(sessionPages[i].pageData);
          backActionDynamicLink = pageData.backActionDynamicLink;
          break;
        }
      }

      if (backActionDynamicLink) {
        window.location.href = backActionDynamicLink;
      } else {
        console.error("Back action dynamic link not found.");
      }
    } else {
      console.error("UpdatedLocalData is not available.");
    }
  };

  const handleAddressClick = (index: number) => {};
  const handleAddressSelection = async (
    index: number,
    selectedPaymentIndex: number
  ) => {
    // Toggle options if the same item is clicked again
    if (selectedAddressIndex === index) {
      setShowOptions(!showOptions); // Assuming you want to toggle options visibility
    } else {
      setSelectedAddressIndex(index); // Set the new selected address index
      setShowOptions(true); // Ensure options are shown for the new selection
    }

    // Toggle the dropdown visibility
    setIsBillingDropdownOpen(!isBillingDropdownOpen);

    try {
      const selectedBillingAddress = billingAddresses[index];
      dispatch(setSelectedBillingAddress(selectedBillingAddress));

      // Prepare the session data to be updated
      const selectedPaymentMethod = paymentMethods[selectedPaymentIndex];

      const updatedSessionData = {
        billingDetailRequestModel: {
          ...UpdatedLocalData.billingDetailRequestModel,
          firstName: selectedBillingAddress.firstName,
          lastName: selectedBillingAddress.lastName,
          address: selectedBillingAddress.address1,
          city: selectedBillingAddress.city,
          state: selectedBillingAddress.state,
          postalCode: selectedBillingAddress.zipCode,
          country: selectedBillingAddress.country,
          // Include payment method details
          paymentMethodId: getSelectedPaymentfromStore?.paymentMethodId,
          asaPaymentMethodCode:
            getSelectedPaymentfromStore?.asaPaymentMethodCode,
          paymentType: getSelectedPaymentfromStore?.paymentType,
          routingNumber: getSelectedPaymentfromStore?.routingNumber,
          last4DigitsOfAccountNumber:
            getSelectedPaymentfromStore?.last4DigitsOfAccountNumber,
          bankName: getSelectedPaymentfromStore?.bankName,
          cardType: getSelectedPaymentfromStore?.cardType,
          cardNumber: getSelectedPaymentfromStore?.cardNumber,
          accountNumber:
            getSelectedPaymentfromStore?.paymentType === "CC"
              ? null
              : getSelectedPaymentfromStore?.accountNumber,
          last4DigitsOfCardNumber:
            getSelectedPaymentfromStore?.last4DigitsOfCardNumber,
          cardHolderName: getSelectedPaymentfromStore?.cardHolderName,
          expirationYear: getSelectedPaymentfromStore?.expirationYear,
          expirationMonth: getSelectedPaymentfromStore?.expirationMonth,
        },
      };

      // Determine the data to update
      let dataToUpdate = UpdatedLocalData || updatedSessionData;

      if (dataToUpdate) {
        try {
          // Set loading state to true before making the API call
          setIsLoading(true);

          await updateSession(
            dataToUpdate,
            updatedSessionData,
            "4",
            "ListPaymentBilling",
            "PaymentBillingInfo"
          );

          // Optionally, navigate to payment screen after successful API call
          // navigate("/payment");
        } catch (error) {
          console.error("Error updating session:", error);
        } finally {
          // Set loading state to false after API call is completed
          setIsLoading(false);
        }
      } else {
        console.error("No session data available to update.");
      }
    } catch (error) {
      console.error(
        "Error updating session with new billing address and payment method:",
        error
      );
    }
  };

  // const handleAddressSelection = async (
  //   index: number,
  //   selectedPaymentIndex: number
  // ) => {
  //   if (selectedAddressIndex === index) {
  //     // If the same item is clicked again, toggle the options off
  //     setShowOptions(true);
  //   } else {
  //     // Show options for the clicked item
  //     setSelectedAddressIndex(index);
  //   }
  //   setSelectedAddressIndex(index);
  //   setIsBillingDropdownOpen(!isBillingDropdownOpen);

  //   try {
  //     const selectedBillingAddress = billingAddresses[index];
  //     dispatch(setSelectedBillingAddress(selectedBillingAddress));

  //     const selectedPaymentMethod = paymentMethods[selectedPaymentIndex]; // Assuming you have selectedPaymentIndex available

  //     // Prepare the session data to be updated
  //     const updatedSessionData = {
  //       billingDetailRequestModel: {
  //         ...UpdatedLocalData.billingDetailRequestModel,
  //         firstName: selectedBillingAddress.firstName,
  //         lastName: selectedBillingAddress.lastName,
  //         address: selectedBillingAddress.address1,
  //         city: selectedBillingAddress.city,
  //         state: selectedBillingAddress.state,
  //         postalCode: selectedBillingAddress.zipCode,
  //         country: selectedBillingAddress.country,
  //         // Include payment method details
  //         paymentMethodId: getSelectedPaymentfromStore?.paymentMethodId,
  //         asaPaymentMethodCode:
  //           getSelectedPaymentfromStore.asaPaymentMethodCode,
  //         paymentType: getSelectedPaymentfromStore.paymentType,
  //         routingNumber: getSelectedPaymentfromStore.routingNumber,
  //         last4DigitsOfAccountNumber:
  //           getSelectedPaymentfromStore.last4DigitsOfAccountNumber,
  //         bankName: getSelectedPaymentfromStore.bankName,
  //         cardType: getSelectedPaymentfromStore.cardType,
  //         cardNumber: getSelectedPaymentfromStore.cardNumber,
  //         accountNumber:
  //           getSelectedPaymentfromStore.paymentType === "CC"
  //             ? null
  //             : getSelectedPaymentfromStore?.accountNumber,
  //         last4DigitsOfCardNumber:
  //           getSelectedPaymentfromStore.last4DigitsOfCardNumber,
  //         cardHolderName: getSelectedPaymentfromStore?.cardHolderName,
  //         expirationYear: getSelectedPaymentfromStore?.expirationYear,
  //         expirationMonth: getSelectedPaymentfromStore?.expirationMonth,
  //       },
  //     };

  //     // Call updateSession function to update the session with new billing address and payment method
  //     //    await updateSession(updatedSessionData);

  //     try {
  //       let dataToUpdate; // Declare variable to hold the data to be updated

  //       // Check if UpdatedLocalData contains data and use it if available
  //       if (UpdatedLocalData) {
  //         dataToUpdate = UpdatedLocalData;
  //       } else {
  //         dataToUpdate = updatedsessionData;
  //       }

  //       if (dataToUpdate) {
  //         // Set loading state to true before making the API call

  //         await updateSession(
  //           dataToUpdate,
  //           updatedSessionData,
  //           "4",
  //           "ListPaymentBilling",
  //           "PaymentBillingInfo"
  //         );
  //         // Navigate to payment screen after successful API call
  //         // navigate("/payment");
  //       } else {
  //         console.error("No session data available to update.");
  //       }
  //     } catch (error) {
  //       setIsLoading(false);
  //       console.error("Error updating session:", error);
  //       // Handle error here
  //     } finally {
  //       // Set loading state to false after API call is completed (whether success or error)
  //     }

  //     // Optionally, navigate to the next screen or handle success
  //     // navigate("/NextScreen");
  //   } catch (error) {
  //     console.error(
  //       "Error updating session with new billing address and payment method:",
  //       error
  //     );
  //     // Handle error scenarios
  //   }
  //   //   navigate("/Address"); // Navigate to the next screen when an address is selected
  // };

  const updateSessionPayment = async (
    updatedSessionData: SessionUpdateRequest,
    paymentRequestResponse: any
  ) => {
    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-GOAL",
      pageCount: updatedSessionData.pageCount,
      status: paymentRequestResponse?.status == "200" ? "Completed" : "Error", // Ensure the status field is a string,
      statusMessage: updatedSessionData.statusMessage,
      sessionPages: [
        ...(Array.isArray(updatedSessionData.sessionPages)
          ? updatedSessionData.sessionPages
          : []),
        {
          asaSessionCode: UpdatedLocalData?.data?.asaSessionCode,

          pageNumber: "3", // Assuming this is the second page
          pageName: "PaymentScreen",
          pageTitle: "PaymentScreen",
          pageDescription: "Description of PaymentScreen",
          pageUrl: "/payment",
          pageStatus:
            paymentRequestResponse?.status == "200" ? "Completed" : "Error",
          pageStatusMessage:
            paymentRequestResponse?.status == "200" ? "Completed" : "Error",
          pageData: JSON.stringify({
            PaymentData: paymentRequestResponse,
          }),
        },
      ],
    };

    try {
      const response = makeApiCall(
        API_CALL_TYPE.PUT_CALL, // Specify the API call type (GET in this case)
        UPDATE_SESSION(), // API endpoint to fetch session data
        handleUpdateSessionResponse, // Callback function to handle successful response
        handleUpdateSessionError, // Callback function to handle error response
        "", // Placeholder for formData argument
        "", // Placeholder for token argument
        sessionUpdateRequest // Pass the session ID in the body // Placeholder for body argument
      );

      return response;
    } catch (error) {
      console.error("Error fetching session:", error);
      throw error;
    }
  };

  const handlePostPaymentResponse = async (response: any) => {
    setIsLoading(false);
    if (response?.status === 400 || response?.status === 500) {
      // Show a toast message indicating the appropriate message
      showCustomToast(response?.message, true);
      setIsLoading(false);
      return; // Exit the function early
    }

    if (response?.status === 422) {
      // Show a toast message indicating the appropriate message
      showCustomToast(response?.data?.message, true);
      setIsLoading(false);
      return; // Exit the function early
    }

    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      }
      // else {
      //   dataToUpdate = updatedsessionData;
      // }

      if (dataToUpdate) {
        await updateSessionPayment(dataToUpdate, response);
        setIsLoading(false);
        // showCustomToast(response?.message, false);

        if (
          response?.data?.message === "Payment Received Successfully." ||
          "Free subscription plan register successfully."
        ) {
          showCustomToast(response?.data?.message, false);

          navigate("/portfolioGoal", { state: response?.data?.message });

          return; // Exit the function early
        } else if (
          response?.data?.message ===
          "The subscription you selected is already active."
        ) {
          showCustomToast(response?.data?.message, true);

          navigate("/portfoliopilot", { state: response?.data?.message });
        }
      }
    } catch (error) {
      setIsLoading(false);
      showCustomToast("Error updating session:", true);
      // Handle error here
    }
  };

  const handleConfirmCVV = (enteredCVV: string) => {
    setCVV(enteredCVV);
    setShowDialogCVV(false);
    handlePayment(
      getSelectedBillingfromStore,
      getSelectedPaymentfromStore,
      enteredCVV
    );
  };

  const handlePostPaymentError = (error: any) => {
    setIsLoading(false);
    if (error?.message === "Request failed with status code 400") {
      showCustomToast("CVV should contain only 3 or 4 digits", true);
    }

    // Handle error here
  };

  const handleapp = async (billingfromstore: any, paymentfromstore: any) => {
    if (paymentfromstore === null) {
      return; // or handle the error appropriately
    }

    // Check CVV only if payment type is 'cc'
    // if (paymentfromstore?.paymentType === "CC") {
    //   setShowDialogCVV(true);
    //   return;
    // } else {
    handlePayment(getSelectedBillingfromStore, getSelectedPaymentfromStore, "");
    // }
  };

  // Function to calculate prorated amount (implement actual logic based on your needs)
  function calculateProratedAmount(previousAmount: number, newAmount: number) {
    // Simplified example for prorated amount calculation
    // Replace with actual calculation logic as needed
    return newAmount - previousAmount;
  }

  const handlePayment = async (
    billingfromStore: any,
    paymentfromStore: any,
    enteredCVV?: string
  ) => {
    setIsLoading(true);

    // Check CVV only if payment type is 'cc'

    //     console.log("djdskdskljdskf" + cvv);
    if (
      paymentfromStore?.paymentType === "cc" &&
      (!/^\d+$/.test(cvv) || !(cvv.length === 3 || cvv.length === 4))
    ) {
      setCVVError("Enter CVV");

      setIsLoading(false);
      showCustomToast(" Error in CVV", true);
      return;
    }

    // const selectedBillingAddress = billingAddresses[index];

    // Proceed with your logic here
    // Determine billing amount based on plan change and trial status
    let billingAmount;

    // Check if `filteredPlansData` exists and `inTrial` is `false`
    if (filteredPlansData && filteredPlansData[0]?.inTrial === false) {
      // Proceed with plan upgrade/downgrade logic
      if (activePlanData && previousPlanData) {
        if (activePlanData.recurringAmount > previousPlanData.recurringAmount) {
          // console.log(
          //   "Active .." +
          //     activePlanData.recurringAmount +
          //     "xcdcd" +
          //     previousPlanData.recurringAmount
          // );
          // Plan upgraded, calculate prorated amount
          billingAmount = calculateProratedAmount(
            previousPlanData.recurringAmount,
            activePlanData.recurringAmount
          );
        } else if (
          activePlanData.recurringAmount < previousPlanData.recurringAmount
        ) {
          // console.log(
          //   "Active .. eleelelele" +
          //     activePlanData.recurringAmount +
          //     "xcdcd" +
          //     previousPlanData.recurringAmount
          // );

          // console.log("PPPPPPP" + subscriptionPlanData?.pageData.oneTimeAmount);
          // Plan downgraded, use the one-time amount or other logic
          billingAmount = activePlanData.recurringAmount; // Or any other logic for downgrade
        } else {
          // No change in plan

          // console.log(
          //   "Active .. eleeleleleklgnklnkhnkhlnh" +
          //     activePlanData.recurringAmount +
          //     "xcdcd" +
          //     previousPlanData.recurringAmount
          // );
          billingAmount = subscriptionPlanData?.pageData.oneTimeAmount;
        }
      } else {
        // console.log("Active .. eleeleleleklgnklnkhnkhlnhghhhuooiuoiuoiu");

        // Fallback in case `activePlanData` or `previousPlanData` is missing
        billingAmount = subscriptionPlanData?.pageData.oneTimeAmount;
      }
    } else {
      // console.log(
      //   "Active .. eleeleleleklgnklnkhnkhlnhghhhuooiuoiuoiubffdhdfgdffdhdfh"
      // );
      // If `inTrial` is true or `filteredPlansData` is not present, use the one-time amount
      billingAmount = subscriptionPlanData?.pageData.oneTimeAmount;
    }

    const requestBody = {
      products: [""],
      billingDetailRequestModel: {
        billingAmount: billingAmount,
        comment: "Subscription Test for gold",
        isRecurring: subscriptionPlanData?.pageData.isRecurring,
        RecurringAmount: subscriptionPlanData?.pageData.oneTimeAmount,
        isSubscription: true,
        consumerSignature: null,
        autoPay: true,
        recurringStartDate: null,
        billingEmail: null,
        paymentMethodId: paymentfromStore?.paymentMethodId,
        paymentMethodCode: paymentfromStore?.asaPaymentMethodCode,
        BillingCycle: subscriptionPlanData?.pageData?.billingCycle,
        isAcceptTerms: true,
        paymentType: null,
        bankAccountNumber: null,
        bankRoutingNumber: null,
        bankName: null,
        cardType: null,
        cardNumber: null,
        expiryMonth: null,
        expiryYear: null,
        firstName: null,
        middleName: null,
        lastName: null,
        CardHolderName:
          paymentfromStore?.paymentType == "ACH"
            ? null
            : paymentfromStore?.cardHolderName,
        CVV: paymentfromStore?.paymentType == "ACH" ? null : cvv,
        subscriptionPlanCode: activePlanData
          ? activePlanData?.subscriptionPlanCode
          : subscriptionPlanData?.pageData.subscriptionPlanCode,
        address: billingfromStore?.address1,
        address2: billingfromStore?.address2,
        city: billingfromStore?.city,
        state: billingfromStore?.state,
        country: billingfromStore?.country,
        postalCode: billingfromStore?.zipCode,
      },
    };

    const response = await makeApiCall(
      API_CALL_TYPE.POST_CALL,
      POST_PAYMENT_API(),
      handlePostPaymentResponse,
      handlePostPaymentError,
      "", // Placeholder for formData argument
      "", // Placeholder for token argument
      requestBody
    );
  };
  const handleMoreOptionsClick = (index: number) => {
    setVisibleOptionsIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleCloseOptions = () => {
    setVisibleOptionsIndex(null);
  };

  useEffect(() => {
    const clearCache = async () => {
      // Clear local storage, session storage, IndexedDB, and caches
      localStorage.clear();
      sessionStorage.clear();

      const deleteIndexedDB = async () => {
        const databases = await indexedDB.databases();
        for (const db of databases) {
          if (db.name) {
            indexedDB.deleteDatabase(db.name);
          }
        }
      };

      const clearCache = async () => {
        const cacheNames = await caches.keys();
        await Promise.all(
          cacheNames.map((cacheName) => {
            return caches.delete(cacheName);
          })
        );
      };

      deleteIndexedDB();
      clearCache();
    };

    clearCache();
  }, []);

  return (
    <div className="relative gradient-bg-listpayments bg-gradient-to-b from-[#705AE2] to-[#490C95] flex flex-col justify-between overflow-y-scroll py-10 text-gray-100 mx-auto space-y-5 h-screen">
      {" "}
      {isLoading && <LoaderComponent />}{" "}
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center space-x-4">
          <img
            className="w-6 h-6 sm:w-8 sm:h-8 ml-5"
            src={arrow_back_ios_new}
            alt="Back"
            onClick={handleBackClick} // Updated onClick handler for the back button
          />
          <p className="custom-style-bugs">Checkout with ASA Pay®</p>
        </div>
      </div>
      <div className="absolute top-24 left-0 card-lay-portfolio right-0 bottom-0 rounded-t-3xl p-4 z-10 overflow-y-auto more-rounded">
        <div className="flex items-center justify-between mt-8 mx-2">
          <p className="payment-heading-list-portfolio font-poppins font-semibold">
            Select or add a payment method.
          </p>
          <div className="image-container">
            <img
              className="inner-image"
              src={dropdown_arrow}
              alt="Inner Dropdown Arrow"
            />
            <img
              className="dropdown-img cursor-pointer"
              src={isDropdownOpen ? arrow_open : dropdown_arrow_close}
              alt="Dropdown"
              onClick={togglePaymentMethods}
            />
          </div>
        </div>
        <p className="mx-3 text-light font-poppins">
          Billing starts after 14 day trial.
        </p>
        {!isDropdownOpen && getSelectedPaymentfromStore && (
          <div className="space-y-2 mt-2">
            <div className="flex items-center justify-between p-2 rounded-lg">
              <div className="flex items-center space-x-2">
                <div className="bg-white rounded p-1">
                  <img
                    src={getImageSource(
                      getSelectedPaymentfromStore.paymentType
                    )}
                    alt={
                      getSelectedPaymentfromStore.paymentType ||
                      "Payment Method"
                    }
                    className="w-8 h-5 rounded"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <span className="mt-name-portfolio truncate text-[#FFFFFF]">
                  {getSelectedPaymentfromStore.paymentMethodName}
                </span>
                <div className="font-poppins text-[#FFFFFF] font-semibold text-base leading-24">
                  ••••{" "}
                  {getSelectedPaymentfromStore.paymentType === "CC"
                    ? getSelectedPaymentfromStore?.last4DigitsOfCardNumber
                    : getSelectedPaymentfromStore?.last4DigitsOfAccountNumber}
                </div>
              </div>
            </div>
          </div>
        )}
        {isDropdownOpen && (
          <div className="space-y-2 X-2 mt-2 max-h-40 overflow-y-auto">
            {isLoading ? null : (
              <div className="space-y-2 mt-2 max-h-40 overflow-y-auto">
                {paymentMethods.map((method, index) => {
                  const isSelected =
                    getSelectedPaymentfromStore?.paymentMethodId ===
                    method?.paymentMethodId;
                  const hasStoredCVV =
                    paymentCVVs[method?.paymentMethodId] !== undefined;

                  return (
                    <div key={index}>
                      <div
                        className={`flex items-center justify-between p-2 rounded-lg cursor-pointer mb-3`}
                        style={{
                          backgroundColor:
                            selectedPaymentIndex === index || isSelected
                              ? "#2c2c2c"
                              : "transparent",
                          boxShadow:
                            selectedPaymentIndex === index || isSelected
                              ? "0px 4px 4px rgba(0, 0, 0, 0.25)"
                              : "none",
                        }}
                        onClick={() =>
                          handlePaymentSelection(index, method.paymentType)
                        }
                      >
                        <div className="flex items-center space-x-2">
                          <div
                            className="form-radio-outer"
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "50%",
                              border: "2px solid #ffffff",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#ffffff",
                              justifyContent: "center",
                              transition: "width 0.2s, height 0.2s",
                            }}
                          >
                            <div
                              style={{
                                width:
                                  selectedPaymentIndex === index || isSelected
                                    ? "8px"
                                    : "0",
                                height:
                                  selectedPaymentIndex === index || isSelected
                                    ? "8px"
                                    : "0",
                                borderRadius: "50%",
                                backgroundColor: "#6B46C1",
                                transition: "width 0.2s, height 0.2s",
                              }}
                            />
                          </div>

                          <div className="bg-white rounded p-1">
                            <img
                              src={getImageSource(method.paymentType)}
                              alt={method.paymentType}
                              className="w-8 h-5 rounded"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                          <span className="mt-name-portfolio truncate-portfolio text-[#FFFFFF]">
                            {method?.paymentMethodName}
                          </span>

                          {(isDropdownOpen && selectedPaymentIndex === index) ||
                          (method.paymentType === "CC" && isSelected) ? (
                            method.paymentType === "CC" ? (
                              <input
                                type="password"
                                placeholder="CVV"
                                value={
                                  paymentCVVs[method?.paymentMethodId] || ""
                                }
                                maxLength={4}
                                onChange={(e) =>
                                  handleCVVChange(e, method?.paymentMethodId)
                                }
                                onBlur={handleCVVBlur}
                                onClick={handleCVVClick}
                                inputMode="numeric"
                                style={{
                                  backgroundColor: "#616161",
                                  borderRadius: "10px",
                                }}
                                className="w-20 h-8 p-2 rounded font-poppins  text-[#FFFFFF] text-base leading-6 outline-none text-center box-border input-bg-gray"
                              />
                            ) : (
                              <div className="font-poppins text-[#4E4E4E] font-semibold text-base leading-24">
                                ••••{" "}
                                {method.paymentType === "CC"
                                  ? method?.last4DigitsOfCardNumber
                                  : method?.last4DigitsOfAccountNumber}
                              </div>
                            )
                          ) : (
                            <div className="font-poppins text-[#4E4E4E] font-semibold text-base leading-24">
                              ••••{" "}
                              {method.paymentType === "CC"
                                ? method?.last4DigitsOfCardNumber
                                : method?.last4DigitsOfAccountNumber}
                            </div>
                          )}
                        </div>

                        {selectedPaymentIndex === index && (
                          <>
                            {moreVertOpen ? (
                              <div className="flex items-center space-x-2 ml-auto">
                                <button
                                  className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                  onClick={() => handleEditPaymentMethod(index)}
                                >
                                  <img
                                    className="w-6 h-6 cursor-pointer"
                                    src={edit_icon}
                                    alt="Edit"
                                  />
                                </button>
                                <button
                                  className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                  onClick={() =>
                                    handleDeletePaymentMethodCards(index)
                                  }
                                >
                                  <img
                                    className="w-6 h-6 cursor-pointer"
                                    src={delete_icon}
                                    alt="Delete"
                                  />
                                </button>
                                <button
                                  className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                  onClick={() => setMoreVertOpen(false)}
                                >
                                  <img
                                    className="w-6 h-6 cursor-pointer"
                                    src={close_icon}
                                    alt="Close"
                                  />
                                </button>
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {/* Add another payment method button */}
        {isDropdownOpen && (
          <button
            className="custom-button-addpayment mt-5 font-poppins mx-10 mb-5"
            onClick={onAddPaymentMethod}
          >
            <img
              src={add_icon}
              alt="Add"
              className="inline-block font-poppins mr-2 w-6 h-6"
            />
            <span className="custom-text-addpayment-portfolio font-semibold">
              Add another payment method
            </span>
          </button>
        )}
        <div
          style={{
            borderTop: "1px solid rgba(255, 255, 255, 0.2)", // White color with 20% opacity            marginLeft: 10,
            marginRight: 10,
            marginTop: 10,
          }}
        ></div>
        {/* Selected payment index */}
        {getSelectedPaymentfromStore &&
          getSelectedPaymentfromStore?.paymentType === "CC" && (
            <>
              {/* Billing address selection */}
              <div className="flex items-center justify-between mt-5 mx-2">
                <p className="payment-heading-list-portfolio font-poppins font-semibold">
                  Select or add billing address.
                </p>
                <div className="image-container">
                  <img
                    className="inner-image"
                    src={dropdown_arrow}
                    alt="Inner Dropdown Arrow"
                  />
                  <img
                    className="dropdown-img cursor-pointer"
                    src={
                      isBillingDropdownOpen ? arrow_open : dropdown_arrow_close
                    }
                    alt="Dropdown"
                    onClick={toggleBillingAddress}
                  />
                </div>
              </div>

              {/* Display selected billing address when dropdown is closed */}
              {!isBillingDropdownOpen &&
                getSelectedBillingfromStore &&
                getSelectedBillingfromStore != null && (
                  <div
                    className="mt-3  rounded-lg p-2 flex items-center justify-between"
                    style={{
                      height: "65px",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="flex items-center space-x-2 p-2">
                      <span
                        className="text-[#FFFFFF] ml-4"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                      >
                        {getSelectedBillingfromStore?.firstName}{" "}
                        {getSelectedBillingfromStore?.lastName},{" "}
                        {getSelectedBillingfromStore?.address1}
                        <br />
                        <span
                          className="text-light font-poppins font-semibold"
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "11px",
                            lineHeight: "22px",
                          }}
                        >
                          {getSelectedBillingfromStore.city}{" "}
                          {getSelectedBillingfromStore.state},{" "}
                          {getSelectedBillingfromStore.zipCode}, US
                        </span>
                      </span>
                    </div>
                  </div>
                )}

              {/* Billing address details when dropdown is open */}
              {isBillingDropdownOpen && billingAddresses.length > 0 && (
                <div className="mb-2 mt-2 max-h-30 overflow-y-auto">
                  {billingAddresses.map((address, index) => {
                    const isOptionsVisible = visibleOptionsIndex === index;
                    const isSelected =
                      getSelectedBillingfromStore?.consumerAddressId ===
                      address?.consumerAddressId;
                    return (
                      <div
                        key={index}
                        className="bg-[#2C2C2C] mt-3  rounded-lg p-2 flex items-center justify-between"
                        style={{
                          height: "65px",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          backgroundColor: "#2C2C2C",
                        }}
                      >
                        <div className="flex items-center space-x-2 p-2">
                          <div
                            onClick={() =>
                              handleAddressSelection(
                                index,
                                getSelectedPaymentfromStore
                              )
                            }
                            className="form-radio-outer"
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "50%",
                              border: "2px solid #ffffff",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#ffffff",
                              justifyContent: "center",
                              transition: "width 0.2s, height 0.2s",
                            }}
                          >
                            <div
                              style={{
                                width:
                                  selectedAddressIndex === index || isSelected
                                    ? "8px"
                                    : "0",
                                height:
                                  selectedAddressIndex === index || isSelected
                                    ? "8px"
                                    : "0",
                                borderRadius: "50%",
                                backgroundColor: "#6B46C1",
                                transition: "width 0.2s, height 0.2s",
                              }}
                            />
                          </div>
                          <span
                            className="text-[#FFFFFF] ml-4"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "20px",
                            }}
                            onClick={() => handleAddressClick(index)}
                          >
                            {address.firstName} {address.lastName},{" "}
                            {address.address1}
                            <br />
                            <span
                              className="text-[#FFFFFF] font-poppins font-semibold"
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "11px",
                                lineHeight: "22px",
                              }}
                            >
                              {address.city}, {address.state}, {address.zipCode}
                              ,{address?.country}.
                            </span>
                          </span>
                        </div>

                        <div className="flex items-center space-x-2">
                          {isOptionsVisible ? (
                            <>
                              <button
                                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                onClick={() => handleEditBillingAddress(index)}
                              >
                                <img
                                  className="w-6 h-6 cursor-pointer"
                                  src={edit_icon}
                                  alt="Edit"
                                />
                              </button>
                              <button
                                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeletePaymentMethod(index);
                                }}
                              >
                                <img
                                  className="w-6 h-6 cursor-pointer"
                                  src={delete_icon}
                                  alt="Delete"
                                />
                              </button>
                              <button
                                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                onClick={handleCloseOptions}
                              >
                                <img
                                  className="w-6 h-6 cursor-pointer"
                                  src={close_icon}
                                  alt="Close"
                                />
                              </button>
                            </>
                          ) : (
                            <button
                              className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                              onClick={() => handleMoreOptionsClick(index)}
                            >
                              <img
                                className="w-6 h-6 cursor-pointer"
                                src={more_vert}
                                alt="More Options"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Add a billing address button */}
              {getSelectedPaymentfromStore &&
                getSelectedPaymentfromStore?.paymentType === "CC" &&
                isBillingDropdownOpen && (
                  <button
                    className="custom-button-addpayment mt-3 font-poppins mx-10 mb-5"
                    onClick={addBilling}
                  >
                    <img
                      src={add_icon}
                      alt="Add"
                      className="inline-block font-poppins mr-2 w-6 h-6"
                    />
                    <span className="custom-text-addpayment font-semibold">
                      Add a billing address
                    </span>
                  </button>
                )}

              <div
                style={{
                  borderTop: "1px solid rgba(255, 255, 255, 0.2)", // White color with 20% opacity
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 10,
                }}
              ></div>

              {showConfirmDialog && (
                <ConfirmDialog
                  title="Delete Billing Address"
                  message="Are you sure you want to delete this billing address?"
                  onCancel={() => {
                    setShowConfirmDialog(false);
                    setDeletePaymentIndex(null);
                  }}
                  onConfirm={() => {
                    // Handle confirm logic here

                    handleConfirmDelete();
                    setShowConfirmDialog(false); // Close dialog after action
                  }}
                  isOpen={showConfirmDialog}
                />
              )}
            </>
          )}
        {showDialogCVV && (
          <ConfirmDialogCVV
            title="ADd CVV"
            message="Please Enter Cvv"
            onCancel={() => setShowDialogCVV(false)}
            onConfirm={handleConfirmCVV} // Ensure this function is called on confirmation
            isOpen={showDialogCVV}
          />
        )}
        {showConfirmDialogPayment && (
          <ConfirmDialog
            title="Delete Payment Method"
            message="Are you sure you want to delete this payment method?"
            onCancel={() => setShowConfirmDialogPayment(false)}
            onConfirm={handleConfirmDeletePaymet} // Ensure this function is called on confirmation
            isOpen={showConfirmDialogPayment}
          />
        )}

        {/* {getSelectedPaymentfromStore ? (
          <div className="plan-item">
            <p>
              <strong>Plan Name:</strong>{" "}
              {JSON.stringify(getSelectedPaymentfromStore?.paymentType)}
            </p>
          </div>
        ) : (
          <p>No current plan available.</p>
        )} */}
        {/* 
        {getSelectedBillingfromStore ? (
          <div className="plan-item">
            <p>
              <strong>Plan Name:</strong>{" "}
              {JSON.stringify(getSelectedBillingfromStore)}
            </p>
          </div>
        ) : (
          <p>No current plan available.</p>
        )} */}

        {/* {activePlanData ? (
          <div className="plan-item">
            <p>
              <strong>Plan Name:</strong>{" "}
              {JSON.stringify(activePlanData?.recurringAmount)}
            </p>
            <p>{JSON.stringify(activePlanData?.recurringAmount)}</p>

            <p>{JSON.stringify(activePlanData?.recurringAmount)}</p>
          </div>
        ) : (
          <p>No current plan available.</p>
        )} */}
        {/* {getSelectedPaymentfromStore ? (
          <div className="plan-item">
            <p>
              <strong>Plan Name:</strong>{" "}
              {JSON.stringify(getSelectedPaymentfromStore)}
            </p>
          </div>
        ) : (
          <p>No current plan available.</p>
        )} 

        {/* {getSelectedBillingfromStore ? (
          <div className="plan-item">
            <p>
              <strong>Plan Name:</strong>{" "}
              {JSON.stringify(getSelectedBillingfromStore)}
            </p>
          </div>
        ) : (
          <p>No current plan available.</p>
        )} */}
        {/* {subscriptionPlanData ? (
          <div className="plan-item">
            <p>
              <strong>Plan Name:</strong> {JSON.stringify(subscriptionPlanData)}
            </p>
          </div>
        ) : (
          <p>No current plan available.</p>
        )} */}
        {/* <div className="plan-item">
          <p>
            <strong>Plan Name:</strong> {JSON.stringify(subscriptionPlanData)}
          </p>
        </div>

        <div className="plan-item">
          <p>
            <strong>Plan Name:</strong>{" "}
            {JSON.stringify(getSelectedPaymentfromStore)}
          </p>
        </div> */}
        {subscriptionPlanData &&
          ((getSelectedPaymentfromStore?.paymentType === "CC" &&
            getSelectedPaymentfromStore != null &&
            getSelectedBillingfromStore != null) ||
            (getSelectedPaymentfromStore?.paymentType === "ACH" &&
              getSelectedPaymentfromStore != null)) && (
            <div className="mt-6 mb-20">
              <h2 className="text-[#FFFFFF] text-[20px] leading-6 mb-4 font-poppins font-bold ml-2">
                Review
              </h2>
              <div className="flex items-center justify-between rounded-lg">
                <div className="w-16 h-16 p-2 rounded-12">
                  <img
                    // onClick={handleReviewAndPayClick}
                    className="w-full h-full cursor-pointer "
                    src={portfolio_logo}
                    alt="Flash"
                  />
                </div>
                <div className="flex-1 text-left ml-2">
                  <p
                    // onClick={handleReviewAndPayClick}
                    className="text-[#FFFFFF] font-semibold font-poppins text-[14px] leading-4 text-left"
                    style={{
                      fontWeight: 600,
                      lineHeight: "18px",
                    }}
                  >
                    PortFolioPilot
                  </p>

                  <p
                    className="text-light font-poppins text-[12px] font-medium leading-4 mt-1"
                    style={{
                      fontWeight: 500,
                      lineHeight: "18px",
                    }}
                  >
                    {(() => {
                      const subscriptionTitle =
                        subscriptionPlanData?.pageData?.subscriptionTitle;

                      switch (subscriptionTitle) {
                        case "Gold":
                        case "Gold Subscription":
                          return "Gold Subscription";
                        case "Platinum":
                          return "Platinum Subscription";
                        case "Free":
                          return "Free Subscription";
                        default:
                          return subscriptionTitle
                            ? `${subscriptionPlanData.pageData.billingCycle} Subscription`
                            : "No subscription plan selected";
                      }
                    })()}
                  </p>
                </div>
                <div className="text-right">
                  {filteredPlansData &&
                  filteredPlansData[0]?.inTrial === false &&
                  activePlanData &&
                  previousPlanData ? (
                    <>
                      <p className="text-[#FFFFFF] font-poppins font-semibold text-[14px]">
                        $
                        {activePlanData?.recurringAmount &&
                        previousPlanData?.recurringAmount &&
                        activePlanData.recurringAmount >
                          previousPlanData.recurringAmount
                          ? activePlanData.recurringAmount
                          : activePlanData?.recurringAmount || "N/A"}
                      </p>
                      <p className="text-light leading-6 text-[12px] font-poppins">
                        {subscriptionPlanData.pageData?.billingCycle ===
                        "Annually"
                          ? "Year"
                          : "Month"}
                      </p>
                      {activePlanData?.recurringAmount >
                        previousPlanData?.recurringAmount && (
                        <p className="text-[#FFFFFF] font-poppins font-semibold text-[14px]">
                          Upgrade Cost: ${formattedProratedDifference || "N/A"}
                        </p>
                      )}
                    </>
                  ) : (
                    <div className="text-right">
                      <p
                        className="text-[#FFFFFF] font-poppins font-semibold text-[14px]"
                        style={{
                          fontWeight: 600,
                          lineHeight: "20px",
                          fontSize: "14px",
                        }}
                      >
                        ${subscriptionPlanData.pageData.recurringAmount}
                      </p>
                      <p
                        className="text-light leading-6 text-[12px] font-poppins"
                        style={{
                          fontWeight: 500,
                          lineHeight: "20px",
                          fontSize: "12px",
                        }}
                      >
                        {subscriptionPlanData
                          ? subscriptionPlanData.pageData?.billingCycle ===
                            "Annually"
                            ? "Year"
                            : "Month"
                          : "No subscription plan selected"}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* Approve Payment Button */}

              <div
                className="mt-3  rounded-lg p-2 flex items-center justify-center bg-[#2C2C2C]"
                style={{
                  height: "65px",
                  borderRadius: "8px",
                }}
              >
                <div className="flex flex-col items-center space-y-2 p-2">
                  <span
                    className="text-[#FFFFFF]"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "12px",
                      lineHeight: "18px",
                      textAlign: "center", // Center text horizontally
                    }}
                  >
                    {filteredPlansData && filteredPlansData[0]?.inTrial
                      ? "Cancel anytime, no payment due date"
                      : activePlanData && previousPlanData
                        ? activePlanData.recurringAmount >
                          previousPlanData.recurringAmount
                          ? `Your plan has been upgraded. Previous plan: $${previousPlanData.recurringAmount}, New plan: $${activePlanData.recurringAmount}`
                          : activePlanData.recurringAmount <
                              previousPlanData.recurringAmount
                            ? `Your plan has been downgraded. Previous plan: $${previousPlanData.recurringAmount}, New plan: $${activePlanData.recurringAmount}`
                            : "No change in plan."
                        : "Cancel anytime, no payment due date"}
                    <br />
                    <span
                      className="font-poppins font-semibold text-light top-2"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "18px",
                        textAlign: "center", // Center text horizontally
                      }}
                    >
                      {/* {filteredPlansData && filteredPlansData[0]?.inTrial
                        ? "Monthly payment starts in 14 days"
                        : "Completed at the End of 30 days/Billing cycle"} */}

                      {filteredPlansData && filteredPlansData[0]?.inTrial
                        ? "Monthly payment starts in 14 days"
                        : activePlanData && previousPlanData
                          ? activePlanData.recurringAmount >
                            previousPlanData.recurringAmount
                            ? `Completed at the End of 30 days/Billing cycle`
                            : activePlanData.recurringAmount <
                                previousPlanData.recurringAmount
                              ? `Completed at the End of 30 days/Billing cycle`
                              : "Completed at the End of 30 days/Billing cycle"
                          : "Monthly payment starts in 14 days"}
                    </span>
                  </span>
                </div>
              </div>

              <div className="fixed bottom-0 left-0 right-0 p-4">
                <button
                  onClick={() =>
                    handleapp(
                      getSelectedBillingfromStore,
                      getSelectedPaymentfromStore
                    )
                  }
                  className="w-full bg-[#705AE2] text-white py-4 rounded-lg mt-6 transition duration-300 focus:outline-none focus:ring-2 font-poppins font-bold shadow-md"
                >
                  PROCEED WITH TRIAL
                </button>
              </div>
            </div>
          )}
        {/* Debugging Information */}
      </div>
    </div>
  );
};

export default PortfolioPayment;
